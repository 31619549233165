import {lazy} from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from '../layout/MainLayout';
import urls from '../utils/urls';

// sample page routing

// ==============================|| MAIN ROUTING ||============================== //

const MaintenanceUnderConstruction = Loadable(lazy(() => import('views/pages/maintenance/UnderConstruction')));

const CommonRoutes = {
    path: urls.dashboard,
    element: (
        <AuthGuard>
            <MainLayout withSidebar={false} />
        </AuthGuard>
    ),
    children: [
        {
            path: urls.underConstruction,
            element: <MaintenanceUnderConstruction />
        },
    ]
};

export default CommonRoutes;
