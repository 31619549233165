export default class EventEmitter {

  eventHandlers = [];

  on(eventName, fn) {

    this.eventHandlers.push({
      name: eventName,
      fn
    });
  }

  off(eventName, fn) {

    this.eventHandlers = this.eventHandlers
      .filter(event => event.name === eventName && event.fn !== fn);
  }

  emit(eventName, ...args) {

    this.eventHandlers
      .filter(event => event.name === eventName)
      .forEach(event => event.fn(...args));
  }
}
