import {useCallback, useEffect, useRef} from 'react';
import {Grid, Typography, useTheme} from '@mui/material';
import { gridSpacing } from '../../store/constant';
import {useSelector} from '../../store';
import {selectCurrentChat, selectUserId} from '../../store/slices/chat';
import getFormatRuDate from '../../utils/getFormatRuDate';
import Message from './Message';

const ChatHistory = () => {

    const theme = useTheme();
    // scroll to bottom when new message is sent or received
    const wrapper = useRef(document.createElement('div'));
    const userId = useSelector(selectUserId);
    const data = useSelector(selectCurrentChat);
    const el = wrapper.current;
    const scrollToBottom = useCallback(() => {

        el.scrollIntoView(false);
    }, [el]);

    useEffect(() => {

        scrollToBottom();

        setTimeout(() => {

            scrollToBottom();

        }, 100);

    }, [data, scrollToBottom]);

    useEffect(() => {

        scrollToBottom();

    }, []);

    if (data === null)
        return null;

    return (
        <Grid container rowSpacing={gridSpacing} ref={wrapper}>
            {
                data.messages.map(message => {

                    const formattedDate = getFormatRuDate({
                        date: new Date(message.date),
                        withTime: true,
                        hideCurrentYear: true,
                        timeIsFirst: true,
                        hideCurrentDayAndMonth: true
                    });
                    const isAdmin = message.owner.ownerType === 'SUPER_TYPE';
                    const isOwner = message.owner.owner === userId;
                    const additionalData = message.hasOwnProperty('additionalData') ? message.additionalData : null;

                    return (
                        <Grid item xs={12} key={message.id}>
                            <Message
                                content={message.content}
                                date={formattedDate}
                                avatar={message.owner.avatar}
                                nickname={message.owner.nickname}
                                isOwner={isOwner}
                                isAdmin={isAdmin}
                                ownerType={message.owner.ownerType}
                                additionalData={additionalData}
                            />
                        </Grid>
                    );
                }
            )}
        </Grid>
    );
};

export default ChatHistory;
