// material-ui
import {useTheme} from '@mui/material/styles';
import logotypeImage from 'assets/images/logotype.svg';
import darkLogotypeImage from 'assets/images/logotype-dark.svg';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return <img src={theme.palette.mode === 'dark' ? darkLogotypeImage : logotypeImage} alt="Юрсканнер" width="179" />;
};

export default Logo;
