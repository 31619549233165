import {useState} from 'react';
import ChatHistory from './ChatHistory';
import {useDispatch, useSelector} from '../../store';
import {
    selectChats,
    selectCurrentChat,
    setCurrentChat,
    selectUserId,
    addPrivateMessage,
} from '../../store/slices/chat';
import EmptyText from './Layout/EmptyText';
import useAuth from '../../hooks/useAuth';
import urls from '../../utils/urls';
import {
    isStageCompletedStatus,
    selectLawCases,
    STAGE_COMPLETED_STATUS,
    STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS
} from '../../store/slices/lawCase';
import ChatLayout from './Layout';
import Drawer from './Drawer';
import DrawerHeader from './Drawer/Header';
import DrawerFooter from './Drawer/Footer';
import {CONTROVERSIAL_SITUATION_STATUS} from '../../store/slices/stage';
import completeAndNextStageByAdmin from '../../api/lawCase/completeAndNextStageByAdmin';
import StageSettings from './StageSettings';
import {useParams} from 'react-router-dom';
import LockClockRoundedIcon from '@mui/icons-material/LockClockRounded';
import LawCaseSettings from './LawCaseSettings';
import Box from '@mui/material/Box';

const PrivateChat = () => {

    const dispatch = useDispatch();
    const {isLoggedIn} = useAuth();
    const chats = useSelector(selectChats);
    const currentChat = useSelector(selectCurrentChat);
    const {stageId: stageIdParam} = useParams();
    const userId = useSelector(selectUserId);
    const lawCases = useSelector(selectLawCases);
    // const lawCase = lawCases && currentChat && lawCases.find(({stages}) => stages.map(({chatId}) => chatId).includes(currentChat.id));
    const lawCase = getLawCase({lawCases, currentChat, stageIdParam});
    // const chatStage = lawCase && currentChat && lawCase.stages.find(({chatId}) => chatId === currentChat.id);
    const chatStage = getStage({currentChat, lawCase, stageIdParam});
    const isFirstStage = lawCase && currentChat && !isStageCompletedStatus(lawCase.stages[0].status);
    const isLastStage = lawCase && currentChat && lawCase.stages.length > 1
        && lawCase.stages.filter(({status}) => status === STAGE_COMPLETED_STATUS).length === (lawCase.stages.length - 1);
    const isConsultation = lawCase && lawCase.stages.length === 1;
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const isAdminChat = currentChat && lawCase && currentChat.id === lawCase.adminChatId;
    const handleOnSend = ({chatId, additionalData = null}) => {

        if (!message)
            return;

        setMessage('');

        addPrivateMessage({chatId, content: message, additionalData});

        const tempCurrentChat = {
            ...currentChat,
            messages: [...currentChat.messages, {
                content: message,
                owner: {
                    owner: userId,
                    ownerType: 'SUPER_TYPE',
                },
                date: Date.now(),
                id: `temporary-${Date.now()}`,
            }]
        };

        dispatch(setCurrentChat(tempCurrentChat));
    };

    const onHeaderButtonClick = e => {

        e.preventDefault();

        if (lawCase) {

            setLoading(true);

            completeAndNextStageByAdmin({stageId: chatStage.id})
                .then(response => {})
                .catch(e => {})
                .then(() => {
                    setLoading(false);
                });
        }
    };

    if (chats === null || !lawCase || !isLoggedIn) {

        return (
            <EmptyText text="Загрузка..."/>
        );
    }

    const {avatar, avatars, nickname, icon} = getChatAvatarAndAvatarsAndName({currentChat, chatStage, isAdminChat});

    return (
        <ChatLayout
            backUrl={getBackUrl(lawCases, lawCase, currentChat)}
            drawerHeader={<DrawerHeader uid={lawCase.uid}/>}
            drawerFooter={<DrawerFooter lawCase={lawCase} selected={isAdminChat} />}
            drawer={<Drawer/>}
            headerButton={
                getHeaderButtonData(
                    chatStage,
                    isConsultation,
                    isFirstStage,
                    isLastStage,
                    lawCase,
                    loading,
                    onHeaderButtonClick,
                )
            }
            message={message}
            setMessage={setMessage}
            handleOnSend={handleOnSend}
            stageSettings={isAdminChat ? <LawCaseSettings lawCase={lawCase}/> : <StageSettings stage={chatStage} />}
            avatar={avatar}
            avatars={avatars}
            nickname={nickname}
            icon={icon}
        >
            <ChatHistory/>
        </ChatLayout>
    );
};

export default PrivateChat;

function getStageButtonText({isFirstStage, isLastStage}) {

    if (isFirstStage)
        return <>Завершить консультацию</>;

    if (isLastStage)
        return <>Завершить дело</>;

    return <>Перевести на&nbsp;следующий этап</>;
}

function getStagesHint({isFirstStage, isFullCaseEnd}) {

    if (isFirstStage)
        return <>Юрист получит вознаграждение после того, как вы завершите консультацию.</>;

    if (isFullCaseEnd)
        return <>Юрист получит вознаграждение после того, как вы завершите дело.</>;

    return <>Юрист получит вознаграждение после того, как вы завершите этап.</>;
}

function getHeaderButtonData(
    chatStage,
    isConsultation,
    isFirstStage,
    isLastStage,
    lawCase,
    loading,
    onClick
) {

    if (chatStage) {

        if ([CONTROVERSIAL_SITUATION_STATUS, STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS].includes(chatStage.status)) {
            return {
                hintText: getStagesHint({isFirstStage, isLastStage}),
                buttonText: getStageButtonText({isFirstStage, isLastStage}),
                disabled: !lawCase || loading,
                loading,
                onClick,
            };
        }
    }

    return null;
}

const getBackUrl = (lawCases, lawCase, currentChat) => {

    if (!lawCases || !currentChat)
        return '';

    if (!lawCase)
        return '';

    return `${urls.caseNewCases}/${lawCase.uid}`;
};


function getLawCase({lawCases, currentChat, stageIdParam}) {

    if (!lawCases)
        return undefined;

    if (currentChat)
        return lawCases.find(({stages, adminChatId}) => stages.map(({chatId}) => chatId).includes(currentChat.id) || (adminChatId && adminChatId === currentChat.id));

    if (stageIdParam)
        return lawCases.find(({stages}) => stages.map(({id}) => id).includes(stageIdParam));

    return undefined;
}

function getStage({currentChat, lawCase, stageIdParam}) {

    if (!lawCase)
        return undefined;

    if (currentChat)
        return lawCase.stages.find(({chatId}) => chatId === currentChat.id);

    if (stageIdParam)
        return lawCase.stages.find(({id}) => id === stageIdParam);

    return undefined;
}

function getChatAvatarAndAvatarsAndName({currentChat, chatStage, isAdminChat}) {

    if (isAdminChat)
        return {avatar: null, avatars: null, nickname: 'Чат c Юрсканнер', icon: null};

    if (currentChat)
        return {avatar: currentChat.avatar, avatars: currentChat.avatars, nickname: currentChat.name, icon: null};

    if (chatStage)
        return {avatar: null, avatars: null, nickname: chatStage.name, icon: <LockClockRoundedIcon/>};

    return null;
}
