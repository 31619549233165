// third-party
import {createSlice} from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import {dispatch} from '../index';
import {privateChatConnection as connection} from '../../features/PrivateChat/Provider';
import addPrivateMessageApi from '../../features/PrivateChat/api/addPrivateMessage';
import getPrivateChatsApi from '../../features/PrivateChat/api/getPrivateChats'

// ----------------------------------------------------------------------

export const PUBLIC_TYPE = 'PUBLIC_TYPE';
export const PRIVATE_TYPE = 'PRIVATE_TYPE';

export const ANON_TYPE = 'ANON_TYPE';
export const CUSTOMER_TYPE = 'CUSTOMER_TYPE';
export const LAWYER_TYPE = 'LAWYER_TYPE';
export const SUPER_TYPE = 'SUPER_TYPE';

const initialState = {
    error: null,
    chats: null,
    currentChat: null,
    searchChatText: '',
    user: null,
    userId: null,
    users: [],
    filter: {
        onlyNew: null,
        count: 1000,
        withSelf: true,
        chatType: PRIVATE_TYPE,
    },
    connection: null,
    chatCreating: false,
    createLink: null,
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setConnection: (state, {payload}) => {state.connection = payload;},
        setChats: (state, {payload}) => {
            console.log({payload});
            state.chats = [...payload];
        },
        setUser: (state, {payload}) => {state.user = payload;},
        setSearchChatText: (state, {payload}) => {state.searchChatText = payload;},
        setCurrentChat: (state, {payload}) => {state.currentChat = payload;},
        setUserId: (state, {payload}) => {state.userId = payload;},
        setChatCreating: (state, {payload}) => {state.chatCreating = payload;},
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER
        getUserSuccess(state, action) {
            state.user = action.payload;
        },

        // GET USER CHATS
        getUserChatsSuccess(state, action) {
            state.chats = action.payload;
        },

        // GET USERS
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },
        setCreateLink: (state, {payload}) => {
            state.createLink = payload;
        },
    }
});


export const {
    setConnection,
    setUser,
    setChats,
    setSearchChatText,
    setCurrentChat,
    setUserId,
    setChatCreating,
    getUserSuccess,
    setCreateLink,
} = slice.actions;

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUser(id) {
    return async () => {
        try {
            const response = await axios.post('/api/chat/users/id', {id});
            dispatch(slice.actions.getUserSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getPrivateChats() {

    getPrivateChatsApi(connection);
}

export function addPrivateMessage({chatId, content}) {

    addPrivateMessageApi(connection, {chatId, content, });
}

export function getUserChats(user) {
    return async () => {
        try {
            const response = await axios.post('/api/chat/filter', {user});
            dispatch(slice.actions.getUserChatsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function insertChat(chat) {
    return async () => {
        try {
            await axios.post('/api/chat/insert', chat);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUsers() {
    return async () => {
        try {
            const response = await axios.get('/api/chat/users');
            dispatch(slice.actions.getUsersSuccess(response.data.users));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export const selectUser = ({chat: {user}}) => user;
export const selectConnection = ({chat: {connection}}) => connection;
export const selectFilter = ({chat: {filter}}) => filter;
export const selectChats = ({chat: {chats}}) => chats;
export const selectSearchChatText = ({chat: {searchChatText}}) => searchChatText;
export const selectCurrentChat = ({chat: {currentChat}}) => currentChat;
export const selectUserId = ({chat: {userId}}) => userId;
export const selectChatCreating = ({chat: {chatCreating}}) => chatCreating;
export const selectCreateLink = ({chat: {createLink}}) => createLink;
