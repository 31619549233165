import {Link} from 'react-router-dom';
import {Card, CardContent, Grid, Typography, useTheme} from '@mui/material';
import {gridSpacing} from '../../store/constant';
import Avatar from '../../ui-component/extended/Avatar';
import Button from '@mui/material/Button';
import useAuth from '../../hooks/useAuth';
import urls from '../../utils/urls';
import Box from '@mui/material/Box';
import FileIcon from '../../ui-component/FileIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import truncateString from '../../utils/truncateString';
import {useState} from 'react';
import getFile from './api/getFile';
import {CUSTOMER_TYPE, LAWYER_TYPE, SUPER_TYPE} from '../../store/slices/chat';

function MessageAdditionalDataCompleteStageButton({chatId}) {

    const url = `${urls.caseStageChat}/${chatId}`;

    return (
        <Box sx={{pt: 2}}>
            <Button
                component={Link}
                to={url}
                color="invertText"
                size="small"
                variant="contained"
                disableElevation
            >
                Перейти к этапу
            </Button>
        </Box>
    );
}

function Files({additionalData, content, invertColor = false}) {

    const [loadingUrl, setLoadingUrl] = useState(null);

    // eslint-disable-next-line no-nested-ternary
    const files = isFileMessage(additionalData)
        ? (Array.isArray(additionalData.values)
            ? additionalData.values
            : [additionalData.values])
        : null;

    const onClick = ({url, fileName}) => {

        setLoadingUrl(url);

        getFile(url)
            .then(({file, contentType}) => {

                if (file) {

                    const blob = new Blob([file], {type: contentType});

                    if (typeof window.navigator.msSaveBlob !== 'undefined') {

                        window.navigator.msSaveBlob(blob, fileName);

                    } else {

                        const URL = window.URL || window.webkitURL;

                        const downloadUrl = URL.createObjectURL(blob);

                        const a = document.createElement('a');

                        if (typeof a.download === 'undefined') {

                            window.location = downloadUrl;

                        } else {

                            a.href = downloadUrl;
                            a.download = fileName;
                            document.body.appendChild(a);
                            a.click();
                        }

                        setTimeout(() => {
                            URL.revokeObjectURL(downloadUrl);
                        }, 100);
                    }
                }

                setLoadingUrl(null);

            })
            .catch(() => {
                setLoadingUrl(null);
            });
    };

    if (!files)
        return null;

    return (
        <>
            {
                content &&
                <Box pb={1.5}/>
            }
            <Grid container spacing={.5}>
                {
                    files.map(({url, fileName}, i) => (
                        <Grid item key={`${url}${i}`}>
                            <LoadingButton
                                size="small"
                                variant="text"
                                color={invertColor ? 'invertText' : 'secondary'}
                                title={fileName}
                                disabled={loadingUrl}
                                loading={loadingUrl === url}
                                loadingPosition="start"
                                onClick={() => onClick({url, fileName})}
                                startIcon={
                                    <FileIcon fileName={fileName} size={17}/>
                                }
                            >
                                {truncateString(fileName, {length: 13})}
                            </LoadingButton>
                        </Grid>
                    ))
                }
            </Grid>
        </>
    );
}

function MessageContent({isAdmin, content, additionalData, invertColor = false}) {

    const theme = useTheme();

    const showMessageAdditionalDataCompleteStageButton = additionalData && additionalData.hasOwnProperty('id') && additionalData.id === 'MESSAGE_ADDITIONAL_DATA_COMPLETE_STAGE';

    return (
        <>
            <Typography
                variant="body2"
                sx={{
                    color: isAdmin ? '#fff' : theme.palette.text.primary,
                    '&::selection': {
                        backgroundColor: isAdmin ? theme.palette.primary.light : 'highlight',
                        color: isAdmin ? theme.palette.text.primary : 'highlighttext',
                    },
                    '&::selection *': {
                        backgroundColor: isAdmin ? theme.palette.primary.light : 'highlight',
                        color: isAdmin ? theme.palette.text.primary : 'highlighttext',
                    },
                }}
                dangerouslySetInnerHTML={{
                    __html: content
                }}
            />
            {
                showMessageAdditionalDataCompleteStageButton &&
                <MessageAdditionalDataCompleteStageButton chatId={additionalData.values.chatId}/>
            }
            <Files
                additionalData={additionalData}
                content={content}
                invertColor={invertColor}
            />
        </>
    );
}

function getBackgroundColor({theme, type}) {

    if (type === SUPER_TYPE)
        return theme.palette.primary.main;

    if (type === CUSTOMER_TYPE)
        return theme.palette.secondary.light;

    if (type === LAWYER_TYPE)
        return theme.palette.primary.light;

    return theme.palette.secondary.light;
}

function Message({avatar = null, content, date, isOwner, nickname, ownerType, additionalData}) {

    const isAdmin = ownerType === SUPER_TYPE;
    const theme = useTheme();
    const backgroundColor = getBackgroundColor({theme, type: ownerType});

    const dateColor = isAdmin
        ? 'rgba(255, 255, 255, .6)'
        : theme.palette.text.primary;

    return (
        <Grid
            container
            rowSpacing={gridSpacing}
            justifyContent={isOwner ? 'flex-end' : 'flex-start'}
        >
            <Grid
                item
                {...(isOwner ? {xs: 11, md: 7} : {xs: 11, md: 7})}
            >
                <Card
                    sx={{
                        display: 'inline-block',
                        backgroundColor,
                        float: isOwner ? 'right' : 'left',
                    }}
                >
                    <CardContent sx={{p: 2, pb: '16px !important'}}>
                        <Grid container spacing={1}>
                            {
                                (nickname || avatar) &&
                                <Grid item xs={12} container spacing={1}>
                                    {
                                        avatar &&
                                        <Grid item>
                                            <Avatar
                                                alt={nickname || ''}
                                                src={avatar}
                                                sx={{width: 24, height: 24}}
                                            />
                                        </Grid>
                                    }
                                    {
                                        nickname &&
                                        <Grid item>
                                            <Typography
                                                variant="h4"
                                                sx={{
                                                    color: isAdmin ? '#fff' : theme.palette.text.primary,
                                                    pt: .3,
                                                    '&::selection, &::selection *': {
                                                        backgroundColor: isAdmin ? theme.palette.primary.light : 'highlight',
                                                        color: isAdmin ? theme.palette.text.primary : 'highlighttext',
                                                    }
                                                }}
                                            >
                                                {nickname}
                                            </Typography>
                                        </Grid>
                                    }
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <MessageContent
                                    isAdmin={isAdmin}
                                    content={content}
                                    additionalData={additionalData}
                                    invertColor={isAdmin && !isOwner}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    align="right"
                                    variant="subtitle2"
                                    sx={{
                                        color: dateColor,
                                        '&::selection': {
                                            backgroundColor: isAdmin ? theme.palette.primary.light : 'highlight',
                                            color: isAdmin ? theme.palette.text.primary : 'highlighttext',
                                        }
                                    }}
                                >
                                    {date}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default Message;

function isFileMessage(additionalData = null) {

    return additionalData && additionalData.hasOwnProperty('type') && additionalData.type === 'ADDITIONAL_DATA_FILE_TYPE';
}
