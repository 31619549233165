import {memo, useEffect, useState} from 'react';

// material-ui
import {Typography} from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import adminMenuItems from 'menu-items/admin';
import useAuth from '../../../../hooks/useAuth';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {

    // const {isLoggedIn, isLawyer, isClient} = useAuth();
    const [items, setItems] = useState([adminMenuItems]);


    const navItems = items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
