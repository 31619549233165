import {Grid} from '@mui/material';
import {COMPLETED_STATUS} from '../../../store/slices/lawCase';
import ActiveLawCase from './ActiveLawCase';
import CompletedLawCase from './CompletedLawCase';

function LawCaseSettings({lawCase}) {

    const isCompleted = lawCase.status === COMPLETED_STATUS;
    console.log({lawCase});
    return (
        <Grid container spacing={1}>
            <Grid item>
                {
                    isCompleted
                        ? (
                            <CompletedLawCase lawCase={lawCase} />
                        )
                        : (
                            <ActiveLawCase lawCase={lawCase} />
                        )
                }
            </Grid>
        </Grid>
    );
}

export default LawCaseSettings;
