import {useEffect, useRef} from 'react';
import {useLocation} from 'react-router-dom';
import urls from '../utils/urls';


export default function useRedirectAfterLoginUrl() {

    const redirectAfterLoginUrl = useRef('');

    const {pathname} = useLocation();

    useEffect(() => {

            if (redirectAfterLoginUrl.current === ''
            && pathname !== '/'
            && !pathname.toLowerCase().includes(urls.signIn.toLowerCase())
            && !pathname.toLowerCase().includes(urls.signOut.toLowerCase())) {

                redirectAfterLoginUrl.current = pathname;
            }


    }, [pathname]);

    return redirectAfterLoginUrl;
}
