// third-party
import {createSlice} from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import {dispatch} from '../index';

// ----------------------------------------------------------------------

const initialState = {
    email: null,
    phone: null,
    roles: null,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setEmail(state, {payload}) {state.email = payload;},
        setPhone(state, {payload}) {state.phone = payload;},
        setRoles(state, {payload}) {state.roles = payload;},
    },
});

// Reducer
export default slice.reducer;

export const {
    setEmail,
    setPhone,
    setRoles,
} = slice.actions;

// ----------------------------------------------------------------------

export const selectEmail = ({user: {email}}) => email;
export const selectPhone = ({user: {phone}}) => phone;
export const selectRoles = ({user: {roles}}) => roles;

export const fetchData = () => {};
