import {useSelector} from '../../store';
import useAuth from '../../hooks/useAuth';
import {useEffect} from 'react';
import {fetchData, selectIsFetching, selectLawCases} from '../../store/slices/lawCase';
import {selectChats} from '../../store/slices/chat';

function LawCasesProvider({children}) {

    const lawCases = useSelector(selectLawCases);
    const isStagesFetching = useSelector(selectIsFetching);
    const {isInitialized, isLoggedIn} = useAuth();
    const chats = useSelector(selectChats);
    const chatMessagesCount = chats ? chats.map(({messages}) => messages.length).reduce((a, b) => a + b, 0) : 0;

    useEffect(() => {

        if (lawCases === null && !isStagesFetching && isInitialized && isLoggedIn)
            fetchData();

    }, [lawCases, isStagesFetching, isInitialized, isLoggedIn]);

    useEffect(() => {

        if (isInitialized && isLoggedIn) {
            fetchData();
        }

    }, [chatMessagesCount, isInitialized, isLoggedIn]);

    return children;
}
export default LawCasesProvider;
