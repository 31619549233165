import {useEffect, useRef, useState} from 'react';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Avatar,
    Box,
} from '@mui/material';

// assets
import {IconBell} from '@tabler/icons';
import Tooltip from '@mui/material/Tooltip/Tooltip';

const NotificationSection = () => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    return (
        <Tooltip title="Уведомлений нет">
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.grey : theme.palette.grey['300'],
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.grey : theme.palette.grey['400'],
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <IconBell stroke={1.5} size="1.3rem" />
                </Avatar>
            </Box>
        </Tooltip>
    );
};

export default NotificationSection;
