import axios from 'utils/axios';

export default async function deactivateLawyer(lawyerId) {

    const response = await axios.post('/api/admin/lawyer/deactivate', {lawyerId});

    console.log(response);

    return response.data;
}
