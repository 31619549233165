import {Grid, IconButton, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

function MessageField({message, onChange, handleOnSend, dataToSend, label = 'Сообщение'}) {

    const setMessage = e => {

        onChange(e.target.value);
    };

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs zeroMinWidth>
                <TextField
                    fullWidth
                    label={label}
                    value={message}
                    onChange={setMessage}
                    multiline
                    maxRows={6}
                />
            </Grid>
            <Grid item>
                <IconButton
                    color="primary"
                    onClick={() => handleOnSend({...dataToSend})}
                    size="large"
                    disabled={!message}
                >
                    <SendIcon/>
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default MessageField;
