// third-party
import {combineReducers} from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import adminReducer from './slices/admin';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import menuLawyers from './slices/lawyers';
import stageReducer from './slices/stage';
import lawCaseReducer from './slices/lawCase';
import chatReducer from './slices/chat';
import anonymousChatReducer from './slices/anonymousChat';

const reducer = combineReducers({
    snackbar: snackbarReducer,
    admin: adminReducer,
    user: userReducer,
    menu: menuReducer,
    lawyers: menuLawyers,
    stage: stageReducer,
    lawCase: lawCaseReducer,
    chat: chatReducer,
    anonymousChat: anonymousChatReducer,
});

export default reducer;
