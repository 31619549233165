import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import urls from '../urls';

function RedirectAfterLoginProvider({children}) {

    const navigate = useNavigate();
    const {isLoggedIn, redirectAfterLoginUrl, clearRedirectAfterLoginUrl, isClient} = useAuth();

    useEffect(() => {

        if (isLoggedIn && redirectAfterLoginUrl) {

            if (redirectAfterLoginUrl.includes(urls.lawyerShortChatsChat) && isClient()) {

                navigate(urls.customerAnonymousChats);

            } else {

                navigate(redirectAfterLoginUrl);
            }

            clearRedirectAfterLoginUrl();
        }

    }, [isLoggedIn, redirectAfterLoginUrl]);

    return children;
}

export default RedirectAfterLoginProvider;
