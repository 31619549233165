export default function getNumeralDeclinationWord(num = 0, words = ['', '', '']) {

    const dozens = Math.abs(num) % 100;
    const units = dozens % 10;

    if (dozens > 10 && dozens < 20)
        return words[2];

    if (units > 1 && units < 5)
        return words[1];

    if (units === 1)
        return words[0];

    return words[2];
}
