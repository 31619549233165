import ReactDOM from 'react-dom';

// third party
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

// project imports
import App from 'App';
import {BASE_PATH} from 'config';
import {store, persister} from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import {ConfigProvider} from 'contexts/ConfigContext';

// load mock apis
// import '_mockApis';

// style + assets
import 'assets/scss/style.scss';

// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persister}>*/}
            <ConfigProvider>
                <BrowserRouter basename={BASE_PATH}>
                    <App />
                </BrowserRouter>
            </ConfigProvider>
        {/*</PersistGate>*/}
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

/*
- В кабинете модератора чат не закреплен на экране и скролится неудобно, в отличие от личного кабинете юриста и заказчика

Я написал от модератора сообщение и не вижу ответы

Юрист ответил, а я в кабинете модератора этот ответ не вижу

- Модератор выносит свое решение и в случае необходимости нажимает на кнопку "Перевести на следующий этап", после чего юристу начисляются деньги за текущий этап, текущий этап закрывается и открывается следующий.
 */
