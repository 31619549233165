import {useState} from 'react';
import {
    Box,
    Button, CardContent, Grid,
    styled,
    useMediaQuery,
    useTheme,
    TextField
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {gridSpacing} from '../../../store/constant';
import CompletedStage from './CompletedStage';
import ActiveStage from './ActiveStage';
import {useParams} from 'react-router-dom';

// const WAIT_STATUS = 'WAIT_STATUS'; // ожидает оплаты
// const ACTIVE_STATUS = 'ACTIVE_STATUS';
// const CHANGE_COST_REQUEST = 'CHANGE_COST_REQUEST'; // юрист изменил стоимость и ждет ответа клиента (клиент согласен/не согласен)
// const WAIT_FOR_LAWYER_APPROVAL_CONSULTATION_STATUS = 'WAIT_FOR_LAWYER_APPROVAL_CONSULTATION_STATUS'; // клиент предложил закончить консультацию (пока не используется)
// const WAIT_FOR_CUSTOMER_APPROVAL_CONSULTATION_STATUS = 'WAIT_FOR_CUSTOMER_APPROVAL_CONSULTATION_STATUS'; // юрист предложил закончить консультацию и ожидает ответ клиента
// const WAIT_FOR_LAWYER_APPROVAL_STATUS = 'WAIT_FOR_LAWYER_APPROVAL_STATUS'; // клиент предложил закончить этап (пока не используется)
// const WAIT_FOR_CUSTOMER_APPROVAL_STATUS = 'WAIT_FOR_CUSTOMER_APPROVAL_STATUS'; // юрист предложил закончить этап и ожидает ответ клиента
// const CUSTOMER_REJECT_STATUS = 'CUSTOMER_REJECT_STATUS'; // клиент отклонил завершение консультации/этапа (ответ "нет")
// const CUSTOMER_REJECT_WITH_REASON_STATUS = 'CUSTOMER_REJECT_WITH_REASON_STATUS'; // клиент описал причину отмены консультации/эатапа
// const CONTROVERSIAL_SITUATION_STATUS = 'CONTROVERSIAL_SITUATION_STATUS'; // спорная ситуация, клиент не согласился закончить консультацию/этап и описал причину, юрист не согласен с причиной
// const CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS = 'CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS'; // спорная ситуация, Юрсканнер ответил в чате
// const COMPLETED_STATUS = 'COMPLETED_STATUS'; // консультация/этап окончена

const ACTIVE_STATUS = 'ACTIVE_STATUS';
const COMPLETED_STATUS = 'COMPLETED_STATUS';

function StageSettings({stage}) {

    const isCompleted = stage && stage.status === 'COMPLETED_STATUS';

    return (
        <Grid container spacing={1}>
            <Grid item>
                {
                    isCompleted
                        ? (
                            <CompletedStage stage={stage} />
                        )
                        : (
                            <ActiveStage stage={stage} />
                        )
                }
            </Grid>
        </Grid>
    );
}

export default StageSettings;
