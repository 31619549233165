// action - state management
import {LOGIN, LOGOUT, REGISTER, PHONE_REQUEST, PHONE_CONFIRM, REDIRECT_AFTER_LOGIN} from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    redirectAfterLoginUrl: null,
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const {user, redirectAfterLoginUrl = null} = action.payload;
            return {
                ...state,
                user,
                redirectAfterLoginUrl,
            };
        }
        case LOGIN: {
            const {user, redirectAfterLoginUrl = null} = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                redirectAfterLoginUrl,
            };
        }
        case LOGOUT: {

            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null
            };
        }
        case PHONE_CONFIRM: {
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user: null
            };
        }
        case REDIRECT_AFTER_LOGIN: {

            return {
                ...state,
                redirectAfterLoginUrl: null,
            };
        }
        default: {
            return {...state};
        }
    }
};

export default accountReducer;
