import {Grid} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import activateStage from '../../../api/lawCase/activateStage';

function CompletedStage({stage}) {

    const [loading, setLoading] = useState(false);

    const onClick = e => {

        e.preventDefault();

        setLoading(true);

        activateStage(stage.id)
            .then(response => {

                console.log({response});
            })
            .catch(error => {

                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    disableElevation
                    size="small"
                    onClick={onClick}
                    disabled={loading}
                    loading={loading}
                >
                    Активировать
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default CompletedStage;
