import Logotype from '../../../ui-component/Logotype';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <a href={process.env.REACT_APP_CP_URL}>
        <Logotype />
    </a>
);

export default LogoSection;
