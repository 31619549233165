import EventEmitter from './EventEmitter';

export default class Api {

    static URL = process.env.REACT_APP_WS_URL;

    static STORAGE_CHAT_TOKEN_KEY = 'chat-token';

    static RESPONSE_STORAGE_CHAT_TOKEN_KEY = 'chat-token';

    static REQUEST_STORAGE_CHAT_TOKEN_KEY = 'chat-token';

    static STORAGE_APP_TOKEN_KEY = 'token';

    static RESPONSE_STORAGE_APP_TOKEN_KEY = 'token';

    static REQUEST_STORAGE_APP_TOKEN_KEY = 'token';

    constructor(url) {

        this.eventEmitter = new EventEmitter();
        this.reconnectTimeout = null;
        this.url = url || Api.URL;
        this.isConnectionOpen = false;
    }

    reconnect(url) {
        console.log('reconnect');
        clearTimeout(this.reconnectTimeout);

        this.reconnectTimeout = setTimeout(() => {

            this.connect(url || this.url);

        }, 1000);
    }

    connect(url) {
        console.log('connect', {url});
        this.connection = new WebSocket(url || this.url);

        this.connection.onopen = e => {
            console.log('Connection established!');
            this.eventEmitter.emit('open', e, this);

            this.isConnectionOpen = true;
        };

        this.connection.onerror = e => {
            console.log('Connection onerror!', e.message);

            this.isConnectionOpen = false;

            this.reconnect(url || this.url);

            this.eventEmitter.emit('error', e, this);
        };

        this.connection.onmessage = e => {
            let data = {};
            try {
                data = JSON.parse(e.data);
            } catch (e) {
                console.log(e);
                data = {};
            }

            Api.setStorageChatTokenByResponse(data);
            Api.setStorageAppTokenByResponse(data);

            this.eventEmitter.emit('message', data, e, this);
        };

        this.connection.onclose = e => {
            console.log('Connection close!');

            this.isConnectionOpen = false;

            this.reconnect(url || this.url);

            this.eventEmitter.emit('close', e, this);
        };
    }

    on(eventName, fn) {
        this.eventEmitter.on(eventName, fn);
    }

    off(eventName, fn) {
        this.eventEmitter.off(eventName, fn);
    }

    close() {
        this.connection.close();
    }

    send(data) {

        try {

            this.waitForConnection(() => {

                if (Api.getStorageChatToken())
                    data[Api.REQUEST_STORAGE_CHAT_TOKEN_KEY] = Api.getStorageChatToken();

                if (Api.getStorageAppToken())
                    data[Api.REQUEST_STORAGE_APP_TOKEN_KEY] = Api.getStorageAppToken();

                this.connection.send(JSON.stringify(data));
            });

        } catch (e) {
            console.log(e);
        }
    }

    static setStorageChatTokenByResponse(data) {

        if (data.hasOwnProperty(Api.RESPONSE_STORAGE_CHAT_TOKEN_KEY) && data[Api.RESPONSE_STORAGE_CHAT_TOKEN_KEY])
            Api.setStorageChatToken(data[Api.RESPONSE_STORAGE_CHAT_TOKEN_KEY]);
    }

    static setStorageChatToken(token) {

        localStorage.setItem(Api.STORAGE_CHAT_TOKEN_KEY, token);
    }

    static getStorageChatToken() {

        return localStorage.getItem(Api.STORAGE_CHAT_TOKEN_KEY);
    }

    static removeStorageChatToken() {

        localStorage.removeItem(Api.STORAGE_CHAT_TOKEN_KEY);
    }

    static setStorageAppTokenByResponse(data) {

        if (data.hasOwnProperty(Api.RESPONSE_STORAGE_APP_TOKEN_KEY) && data[Api.RESPONSE_STORAGE_APP_TOKEN_KEY])
            Api.setStorageAppToken(data[Api.RESPONSE_STORAGE_APP_TOKEN_KEY]);
    }

    static setStorageAppToken(token) {

        localStorage.setItem(Api.STORAGE_APP_TOKEN_KEY, token);
    }

    static getStorageAppToken() {

        return localStorage.getItem(Api.STORAGE_APP_TOKEN_KEY);
    }

    static removeStorageAppToken() {

        localStorage.removeItem(Api.STORAGE_APP_TOKEN_KEY);
    }

    waitForConnection(callback) {

        if (this.isConnectionOpen) {

            callback();

        } else {

            setTimeout(() => {

                this.waitForConnection(callback);

            }, 500);
        }
    }
}
