export default function formatChats(chats = []) {

    return chats.map(chat => ({
        ...chat,
        messages: chat.messages.map((message) => ({
                ...message,
                owner: chat.nicknames.find(({owner}) => owner === message.owner)
            }))
    }));
}
