import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../index';
import getControversialStages from '../../api/lawCase/getControversialStages';

export const WAIT_STATUS = 'WAIT_STATUS';
export const ACTIVE_STATUS = 'ACTIVE_STATUS';
export const WAIT_FOR_CUSTOMER_APPROVAL_STATUS = 'WAIT_FOR_CUSTOMER_APPROVAL_STATUS';
export const CUSTOMER_REJECT_STATUS = 'CUSTOMER_REJECT_STATUS';
export const CUSTOMER_REJECT_WITH_REASON_STATUS = 'CUSTOMER_REJECT_WITH_REASON_STATUS';
export const CONTROVERSIAL_SITUATION_STATUS = 'CONTROVERSIAL_SITUATION_STATUS';
export const CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS = 'CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS';
export const COMPLETED_STATUS = 'COMPLETED_STATUS';

const initialState = {
    stages: null,
    currentStage: null,
    isFetching: null,
    isFetched: null,
};

const slice = createSlice({
    name: 'stage',
    initialState,
    reducers: {
        setIsFetching: (state, {payload}) => {state.isFetching = payload;},
        setIsFetched: (state, {payload}) => {state.isFetched = payload;},
        setStages: (state, {payload}) => {

            state.stages = payload.map(stage => {

                if (state.stages) {

                    const oldStage = state.stages.find(({id}) => id === stage.id);

                    if (oldStage && !stage.lawCase.files && !stage.lawCase.isFileRequesting)
                        return {...stage, lawCase: {files: oldStage.lawCase.files, isFileRequesting: oldStage.lawCase.isFileRequesting}};
                }

                return stage;
            });
        },
        addStage: (state, {payload}) => {
            state.stages = [payload, ...state.stages];
        },
        updateStage: (state, {payload}) => {
            state.stages = [...state.stages]
                .map(stage => stage.id === payload.id
                    ? payload
                    : stage
                )
            ;
        },
    },
});

export const {
    setIsFetching,
    setIsFetched,
    setStages,
    addLawCase,
    updateStage,
} = slice.actions;

// Reducer
export default slice.reducer;

export const selectIsFetching = ({stage: {isFetching}}) => isFetching;
export const selectIsFetched = ({stage: {isFetched}}) => isFetched;
export const selectStages = ({stage: {stages}}) => stages;

export const fetchData = async () => {

    dispatch(slice.actions.setIsFetching(true));

    getControversialStages()
        .then(({stages}) => {

            dispatch(slice.actions.setStages(stages));
            dispatch(slice.actions.setIsFetching(false));
        })
        .catch(error => {

            console.log('fetchData getLawCases', {error});

            dispatch(slice.actions.setIsFetched(true));
        });
};
