import {useNavigate, useParams} from 'react-router-dom';
import {
    Box,
    Divider,
    List,
    Typography,
    useTheme
} from '@mui/material';
import {useDispatch, useSelector} from '../../../store';
import {
    selectLawCases,
    STAGE_COMPLETED_STATUS, STAGE_CONTROVERSIAL_SITUATION_STATUS, STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS,
    STAGE_WAIT_STATUS
} from '../../../store/slices/lawCase';
import {
    selectWithNewPrivateChatType,
    selectWithOneTimeConsultationPrivateChatType
} from '../../../store/slices/notification';
import {selectChats, selectCurrentChat, selectSearchChatText, setCurrentChat} from '../../../store/slices/chat';
import urls from '../../../utils/urls';
import StageListItem from './StageListItem';


const StageList = () => {

    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chats = useSelector(selectChats);
    const searchChatText = useSelector(selectSearchChatText);
    const currentChat = useSelector(selectCurrentChat);
    const {stageId: stageIdParam} = useParams();
    // const withNewPrivateChatType = useSelector(selectWithNewPrivateChatType);
    // const withOneTimeConsultationPrivateChatType = useSelector(selectWithOneTimeConsultationPrivateChatType);
    const lawCases = useSelector(selectLawCases);
    // const lawCase = lawCases && currentChat && lawCases.find(({stages}) => stages.map(({chatId}) => chatId).includes(currentChat.id));
    const lawCase = getLawCase({lawCases, currentChat, stageIdParam});
    // const chatStage = lawCase && currentChat && lawCase.stages.find(({chatId}) => chatId === currentChat.id);
    const chatStage = getStage({currentChat, lawCase, stageIdParam});

    let filteredChats = [...chats];

    filteredChats = filteredChats.filter(({name}) => searchChatText
        ? name.toLowerCase().indexOf(searchChatText.toLowerCase()) > -1
        : true
    );

    const _setCurrentChat = chat => {

        dispatch(setCurrentChat(chat));

        if (chat)
            navigate(`${urls.caseStageChat}/${chat.id}`);
    };

    const stageGroups = [];

    for (let i = 0; i < lawCase.stages.length; i++) {

        const stage = lawCase.stages[i];

        if (!stageGroups.find(({id}) => id === stage.group.id))
            stageGroups.push(stage.group);
    }

    const formattedStageGroups = stageGroups
        .map(group => ({
            ...group, stages: lawCase.stages.filter(stage => stage.group.id === group.id)
        }));

    return (
        <List component="nav" sx={{py: 0}}>
            {
                formattedStageGroups.map((group, groupIndex) => {

                    const isGroupDisabled = group.stages.length === group.stages.filter(({history, status}) =>
                        !history.includes(STAGE_CONTROVERSIAL_SITUATION_STATUS) && !history.includes(STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS) &&
                        status !== STAGE_CONTROVERSIAL_SITUATION_STATUS && status !== STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS
                    ).length;

                    const isLast = formattedStageGroups.length - 1 === groupIndex;

                    const isGroupComplete = group.stages.length === group.stages.filter(({status}) => status === STAGE_COMPLETED_STATUS).length;

                    return (
                        <Box key={group.id}>
                            <Box pt={2}>
                                <Typography
                                    component="div"
                                    variant="h4"
                                    sx={{
                                        pb: 1,
                                        px: 2,
                                        // eslint-disable-next-line no-nested-ternary
                                        color: isGroupDisabled
                                            ? theme.palette.grey[600]
                                            : isGroupComplete
                                                ? theme.palette.secondary.main
                                                : theme.palette.primary.main,
                                        opacity: isGroupDisabled ? .38 : 1,
                                    }}
                                >
                                    {group.name}
                                </Typography>

                                {
                                    group.stages
                                        // .filter(({history}) => {
                                        //     console.log({history});
                                        //     return history.includes(STAGE_CONTROVERSIAL_SITUATION_STATUS) || history.includes(STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS);
                                        // })
                                        .map((stage, stageIndex) => (
                                            <StageListItem
                                                key={stage.id}
                                                filteredChats={filteredChats}
                                                stage={stage}
                                                // withNewPrivateChatType={withNewPrivateChatType}
                                                // withOneTimeConsultationPrivateChatType={withOneTimeConsultationPrivateChatType}
                                                selected={chatStage && chatStage.id === stage.id}
                                                setCurrentChat={_setCurrentChat}
                                                isFirstStage={groupIndex + stageIndex === 0}
                                            />
                                        ))
                                }
                            </Box>
                            {
                                !isLast &&
                                <Divider/>
                            }
                        </Box>
                    );
                })
            }
        </List>
    );
};

export default StageList;



function getLawCase({lawCases, currentChat, stageIdParam}) {

    if (!lawCases)
        return undefined;

    if (currentChat)
        return lawCases.find(({stages, adminChatId}) => stages.map(({chatId}) => chatId).includes(currentChat.id) || (adminChatId && adminChatId === currentChat.id));

    if (stageIdParam)
        return lawCases.find(({stages}) => stages.map(({id}) => id).includes(stageIdParam));

    return undefined;
}

function getStage({currentChat, lawCase, stageIdParam}) {

    if (!lawCase)
        return undefined;

    if (currentChat)
        return lawCase.stages.find(({chatId}) => chatId === currentChat.id);

    if (stageIdParam)
        return lawCase.stages.find(({id}) => id === stageIdParam);

    return undefined;
}
