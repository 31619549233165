export default function getTextPreview(text, {maxLetterLength} = {maxLetterLength: 30}) {

    if (text.length < maxLetterLength)
        return text;

    const titleParts = text.split(' ');
    const formattedTitleParts = [];

    for (let i = 0, letterLength = 0; i < titleParts.length; i++) {
        letterLength += titleParts[i].length + 1;

        if (letterLength >= maxLetterLength)
            break;

        formattedTitleParts.push(titleParts[i]);
    }

    const formattedTitle = formattedTitleParts.join(' ');

    if (!/[\p{Alpha}\p{M}\p{Nd}\p{Pc}\p{Join_C}]$/igu.test(formattedTitle))
        return `${formattedTitle.slice(0, formattedTitle.length - 1)}...`;

    return `${formattedTitle}...`;
};
