import {useEffect} from 'react';
import {fetchLawyers} from '../../store/slices/lawyers';
import {useDispatch} from '../../store';
import useAuth from '../../hooks/useAuth';

function LawyersProvider({children}) {

    const dispatch = useDispatch();
    const {isInitialized, isLoggedIn} = useAuth();

    useEffect(() => {

        if (isInitialized && isLoggedIn)
        dispatch(fetchLawyers());

    }, [isInitialized, isLoggedIn]);

    return children;
}

export default LawyersProvider;
