import {createSlice} from '@reduxjs/toolkit';
import {dispatch} from '../index';
import getLawCasesWithControversialStages from '../../api/lawCase/getLawCasesWithControversialStages';

export const ONLINE_FORMAT = 'ONLINE_FORMAT';
export const OFFLINE_FORMAT = 'OFFLINE_FORMAT';

export const PLAINTIFF_CLIENT_ROLE = 'PLAINTIFF_CLIENT_ROLE';
export const DEFENDANT_CLIENT_ROLE = 'DEFENDANT_CLIENT_ROLE';
export const THIRD_CLIENT_ROLE = 'THIRD_CLIENT_ROLE';

export const MIN_QUESTION_LENGTH = 40;
export const MAX_QUESTION_LENGTH = 10000;

export const FROM_ANONYMOUS_STATUS = 'FROM_ANONYMOUS_STATUS'; // подбираются юристы, зяавка без клиента
export const LAWYER_SELECTION_STATUS = 'LAWYER_SELECTION_STATUS'; // подбираются юристы
export const LAWYERS_SELECTED_STATUS = 'LAWYERS_SELECTED_STATUS'; // юристы подобраны
export const CONSULTATION_STATUS = 'CONSULTATION_STATUS'; // клиент выбрал юриста, чат консультации, клиент внес предоплату за консультацию
export const FULL_CASE_STATUS = 'FULL_CASE_STATUS'; // ведение дела, клиент внес предоплату за ведение дела
export const CUSTOMER_CANCEL_STATUS = 'CUSTOMER_CANCEL_STATUS'; // клиент отменил заявку (чат)
export const LAWYER_REFUSED_CONTINUE_FULL_CASE_STATUS = 'LAWYER_REFUSED_CONTINUE_FULL_CASE_STATUS'; // юрист отказался вести дело после оплаты
export const COMPLETE_REQUEST_BY_CUSTOMER_STATUS = 'COMPLETE_REQUEST_BY_CUSTOMER_STATUS'; // клиент хочет отказаться от дальнейшей работы и ждет Юрсканнер
export const COMPLETE_REQUEST_BY_CUSTOMER_WITH_JURSCANNER_STATUS = 'COMPLETE_REQUEST_BY_CUSTOMER_WITH_JURSCANNER_STATUS'; // клиент хочет отказаться от дальнейшей работы и Юрсканнер ответил
export const COMPLETED_STATUS = 'COMPLETED_STATUS';

export const STAGE_WAIT_STATUS = 'WAIT_STATUS';
export const STAGE_ACTIVE_STATUS = 'ACTIVE_STATUS';
export const STAGE_WAIT_FOR_LAWYER_APPROVAL_CONSULTATION_STATUS = 'WAIT_FOR_LAWYER_APPROVAL_CONSULTATION_STATUS';
export const STAGE_WAIT_FOR_CUSTOMER_APPROVAL_CONSULTATION_STATUS = 'WAIT_FOR_CUSTOMER_APPROVAL_CONSULTATION_STATUS';
export const STAGE_WAIT_FOR_LAWYER_APPROVAL_STATUS = 'WAIT_FOR_LAWYER_APPROVAL_STATUS';
export const STAGE_WAIT_FOR_CUSTOMER_APPROVAL_STATUS = 'WAIT_FOR_CUSTOMER_APPROVAL_STATUS';
export const STAGE_CHANGE_COST_REQUEST = 'CHANGE_COST_REQUEST';
export const STAGE_CUSTOMER_REJECT_STATUS = 'CUSTOMER_REJECT_STATUS';
export const STAGE_CUSTOMER_REJECT_WITH_REASON_STATUS = 'CUSTOMER_REJECT_WITH_REASON_STATUS';
export const STAGE_CONTROVERSIAL_SITUATION_STATUS = 'CONTROVERSIAL_SITUATION_STATUS';
export const STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS = 'CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS';
export const STAGE_COMPLETED_STATUS = 'COMPLETED_STATUS';

export const isOnlineFormat = format => format === ONLINE_FORMAT;
export const isOfflineFormat = format => format === OFFLINE_FORMAT;

export const isPlaintiffClientRole = role => role === PLAINTIFF_CLIENT_ROLE;
export const isDefendantClientRole = role => role === DEFENDANT_CLIENT_ROLE;
export const isThirdClientRole = role => role === THIRD_CLIENT_ROLE;

export const isQuestionValid = question => !!question && question.length >= MIN_QUESTION_LENGTH && question.length <= MAX_QUESTION_LENGTH;

export const isFromAnonymousStatus = status => FROM_ANONYMOUS_STATUS === status;
export const isLawyerSelectionStatus = status => LAWYER_SELECTION_STATUS === status || status === null;
export const isLawyersSelectedStatus = status => LAWYERS_SELECTED_STATUS === status;
export const isConsultationStatus = status => CONSULTATION_STATUS === status;
export const isFullCaseStatus = status => FULL_CASE_STATUS === status;
export const isCustomerCancelStatus = status => CUSTOMER_CANCEL_STATUS === status;
export const isLawyerRefusedContinueFullCaseStatus = status => LAWYER_REFUSED_CONTINUE_FULL_CASE_STATUS === status;

export const isStageWaitStatus = status => status === STAGE_WAIT_STATUS;
export const isStageActiveStatus = status => status === STAGE_ACTIVE_STATUS;
export const isStageWaitForLawyerApprovalConsultationStatus = status => status === STAGE_WAIT_FOR_LAWYER_APPROVAL_CONSULTATION_STATUS;
export const isStageWaitForCustomerApprovalConsultationStatus = status => status === STAGE_WAIT_FOR_CUSTOMER_APPROVAL_CONSULTATION_STATUS;
export const isStageWaitForLawyerApprovalStatus = status => status === STAGE_WAIT_FOR_LAWYER_APPROVAL_STATUS;
export const isStageWaitForCustomerApprovalStatus = status => status === STAGE_WAIT_FOR_CUSTOMER_APPROVAL_STATUS;
export const isChangeCostRequest = status => status === STAGE_CHANGE_COST_REQUEST;
export const isStageCustomerRejectStatus = status => status === STAGE_CUSTOMER_REJECT_STATUS;
export const isStageCustomerRejectWithReasonStatus = status => status === STAGE_CUSTOMER_REJECT_WITH_REASON_STATUS;
export const isStageControversialSituationStatus = status => status === STAGE_CONTROVERSIAL_SITUATION_STATUS;
export const isStageControversialSituationWithJurscannerStatus = status => status === STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS;
export const isStageCompletedStatus = status => status === STAGE_COMPLETED_STATUS;

export const mapFormatToText = {
    [ONLINE_FORMAT]: 'Онлайн',
    [OFFLINE_FORMAT]: 'Очно'
};

export const mapRoleToText = {
    [PLAINTIFF_CLIENT_ROLE]: 'Истец',
    [DEFENDANT_CLIENT_ROLE]: 'Ответчик',
    [THIRD_CLIENT_ROLE]: 'Третья сторона'
};

const initialState = {
    lawCaseCategories: null,
    lawCaseCategoryGroups: null,
    regions: [],
    lawCases: null,
    currentLawCase: null,
    isFetching: null,
    isFetched: null,
};

const slice = createSlice({
    name: 'lawCase',
    initialState,
    reducers: {
        setIsFetching: (state, {payload}) => {state.isFetching = payload;},
        setIsFetched: (state, {payload}) => {state.isFetched = payload;},
        removeLawCases: (state, {payload}) => {

            state.lawCases = [...state.lawCases]
                .map(lawCase => ({...lawCase}))
                .filter(({id}) => payload.indexOf(id) === -1);
        },
        setLawCases: (state, {payload}) => {

            state.lawCases = payload.map(lawCase => {

                if (state.lawCases) {

                    const oldLawCase = state.lawCases.find(({id}) => id === lawCase.id);

                    if (oldLawCase && !lawCase.files && !lawCase.isFileRequesting)
                        return {...lawCase, files: oldLawCase.files, isFileRequesting: oldLawCase.isFileRequesting};
                }

                return lawCase;
            });
        },
        addLawCase: (state, {payload}) => {
            state.lawCases = [payload, ...state.lawCases];
        },
        updateLawCase: (state, {payload}) => {
            state.lawCases = [...state.lawCases]
                .map(lawCase => lawCase.id === payload.id
                    ? payload
                    : lawCase
                )
            ;
        },
        setLawCaseCategories: (state, {payload}) => {state.lawCaseCategories = payload;},
        setLawCaseCategoryGroups: (state, {payload}) => {state.lawCaseCategoryGroups = payload;},
        setRegions: (state, {payload}) => {state.regions = payload;},
    }
});

export const {
    setIsFetching,
    setIsFetched,
    removeLawCases,
    setLawCases,
    addLawCase,
    updateLawCase,
    setLawCaseCategories,
    setLawCaseCategoryGroups,
    setRegions,
} = slice.actions;

// Reducer
export default slice.reducer;

export const selectLawCases = ({lawCase: {lawCases}}) => lawCases;
export const selectIsFetching = ({lawCase: {isFetching}}) => isFetching;
export const selectIsFetched = ({lawCase: {isFetched}}) => isFetched;
export const selectLawCaseCategories = ({lawCase: {lawCaseCategories}}) => lawCaseCategories;
export const selectRegions = ({lawCase: {regions}}) => regions;
export const selectLawCaseCategoryGroups = ({lawCase: {lawCaseCategoryGroups}}) => lawCaseCategoryGroups;

export const fetchData = async () => {

    dispatch(slice.actions.setIsFetching(true));

    getLawCasesWithControversialStages()
        .then(({lawCases, categoryGroups, regions, categories}) => {

            dispatch(slice.actions.setLawCases(lawCases));
            dispatch(slice.actions.setIsFetching(false));
            dispatch(slice.actions.setLawCaseCategoryGroups(categoryGroups));
            dispatch(slice.actions.setRegions(regions));
            dispatch(slice.actions.setLawCaseCategories(categories));
            dispatch(slice.actions.setIsFetched(true));
        })
        .catch(error => {

            dispatch(slice.actions.setIsFetched(true));
        });
};
