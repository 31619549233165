import {lazy} from 'react';
// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import urls from '../utils/urls';
import ConsultationProvider from '../features/PrivateChat/Provider';
import DefaultPage from '../views/DefaultPage';
import ControversialStages from '../views/admin/ControversialStages';
import ControversialStagesList from '../views/admin/ControversialStages/ControversialStageList';
import LawyersProvider from '../utils/providers/LawyersProvider';
import StagesProvider from '../utils/providers/StagesProvaider';
import LawCasesProvider from '../utils/providers/LawCasesProvider';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import Stages from '../views/admin/Cases/Stages';
import AnonymousChatsProvider from '../utils/providers/AnonymousChatsProvider';

// sample page routing
const Dashboard = Loadable(lazy(() => import('views/admin/Dashboard')));
const Lawyers = Loadable(lazy(() => import('views/admin/Lawyers')));
const Cases = Loadable(lazy(() => import('views/admin/Cases')));
const Case = Loadable(lazy(() => import('views/admin/Cases/Case')));
const CaseList = Loadable(lazy(() => import('views/admin/Cases/CaseList')));
const AnonymousChats = Loadable(lazy(() => import('views/admin/Chats')));
const AnonymousChatList = Loadable(lazy(() => import('views/admin/Chats/ChatList')));
const AnonymousChat = Loadable(lazy(() => import('views/admin/Chats/Chat')));

const MainRoutes = {
    path: urls.root,
    element: (
        <AuthGuard>
            <AnonymousChatsProvider>
                <ConsultationProvider>
                    <LawyersProvider>
                        <StagesProvider>
                            <LawCasesProvider>
                                <MainLayout/>
                            </LawCasesProvider>
                        </StagesProvider>
                    </LawyersProvider>
                </ConsultationProvider>
            </AnonymousChatsProvider>
        </AuthGuard>
    ),
    children: [
        {
            path: urls.root,
            element: <Lawyers/>
        },
        {
            path: urls.lawyers,
            element: <Lawyers/>
        },
        {
            path: urls.cases,
            element: <Cases/>,
            children: [
                {
                    path: urls.cases,
                    element: <CaseList/>
                },
                {
                    path: urls.newCases,
                    element: <CaseList/>
                },
                {
                    path: urls.activeCases,
                    element: <CaseList/>
                },
                {
                    path: urls.completedCases,
                    element: <CaseList/>
                },
                {
                    path: `${urls.case}/:lawCaseUid`,
                    element: <Case/>
                },
                {
                    path: `${urls.caseNewCases}/:lawCaseUid`,
                    element: <Case/>
                },
                {
                    path: `${urls.caseActiveCases}/:lawCaseUid`,
                    element: <Case/>
                },
                {
                    path: `${urls.caseCompletedCases}/:lawCaseUid`,
                    element: <Case/>
                },
            ]
        },
        {
            path: `${urls.caseStageChat}/:privateChatId`,
            element: <Stages/>
        },
        {
            path: `${urls.caseStage}/:stageId`,
            element: <Stages/>
        },
        {
            path: urls.anonymousChats,
            element: <AnonymousChats/>,
            children: [
                {
                    path: urls.anonymousChats,
                    element: <AnonymousChatList/>
                },
                {
                    path: `${urls.anonymousChat}/:anonymousChatId`,
                    element: <AnonymousChat/>
                },
            ],
        },
    ]
};

export default MainRoutes;
