import MessageField from './MessageField';
import {selectCurrentChat} from '../../../store/slices/chat';
import {
    selectLawCases,
} from '../../../store/slices/lawCase';
import {useSelector} from '../../../store';
import Loading from './Loading';


function Field({message, setMessage, handleOnSend}) {

    const currentChat = useSelector(selectCurrentChat);
    const lawCases = useSelector(selectLawCases);
    const lawCase = lawCases && currentChat && lawCases.find(({stages, adminChatId}) => stages.map(({chatId}) => chatId).includes(currentChat.id) || (adminChatId && adminChatId === currentChat.id));

    if (!lawCase)
        return <Loading/>;

    return (
        <MessageField
            message={message}
            onChange={setMessage}
            handleOnSend={handleOnSend}
            dataToSend={{chatId: currentChat.id}}
        />
    );
}

export default Field
