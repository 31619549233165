import {Grid} from '@mui/material';
import {gridSpacing} from '../../../store/constant';
import {useState} from 'react';
import {LoadingButton} from '@mui/lab';
import activateLawCase from '../../../api/lawCase/activateLawCase';

function CompletedLawCase({lawCase}) {

    const [loading, setLoading] = useState(false);

    const onClick = e => {

        e.preventDefault();

        setLoading(true);

        activateLawCase({lawCaseId: lawCase.id})
            .then(response => {

                console.log({response});
            })
            .catch(error => {

                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>

                <LoadingButton
                    variant="contained"
                    disableElevation
                    size="small"
                    onClick={onClick}
                    disabled={loading}
                    loading={loading}
                >
                    Активировать
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default CompletedLawCase;
