import {createSlice, current} from '@reduxjs/toolkit';
import {dispatch} from '../index';
import getChats from '../../api/anonymousChat/getChats';
import blockChatApi from '../../api/anonymousChat/blockChat';
import blockMessageApi from '../../api/anonymousChat/blockMessage';
import getRegionsAndCategories from '../../api/anonymousChat/getRegionsAndCategories';
import setCategoriesApi from '../../api/anonymousChat/setCategories';

const initialState = {
    currentChat: null,
    chats: null,
    chatsTotal: null,
    messagesTotal: null,
    categories: null,
    isFetched: false,
    updatingChatId: null,
    updatingMessageId: null,
    updatingCategoriesChatId: null,
    filter: {
        offset: 0,
        count: 21,
    },
};

const slice = createSlice({
    name: 'anonymousChat',
    initialState,
    reducers: {
        setCurrentChat: (state, {payload}) => {state.currentChat = payload;},
        setChats: (state, {payload}) => {state.chats = payload;},
        setChatsTotal: (state, {payload}) => {state.chatsTotal = payload;},
        setMessagesTotal: (state, {payload}) => {state.messagesTotal = payload;},
        setCategories: (state, {payload}) => {state.categories = payload;},
        setFilter: (state, {payload}) => {state.filter = payload;},
        setIsFetched: (state, {payload}) => {state.isFetched = payload;},
        updateChat: (state, {payload}) => {

            const currentState = current(state);

            const newChats = currentState.chats.map(chat =>
                chat.id === payload.id
                    ? {...chat, ...payload}
                    : chat
            );

            state.chats = [...newChats];
        },
        setUpdatingChatId: (state, {payload}) => {state.updatingChatId = payload;},
        setUpdatingMessageId: (state, {payload}) => {state.updatingMessageId = payload;},
        setUpdatingCategoriesChatId: (state, {payload}) => {state.updatingCategoriesChatId = payload;},
    }
});

export const {
    setCurrentChat,
    setChats,
    setChatsTotal,
    setMessagesTotal,
    setCategories,
    setFilter,
    setIsFetched,
    updateChat,
    setUpdatingChatId,
    setUpdatingMessageId,
    setUpdatingCategoriesChatId,
} = slice.actions;

export default slice.reducer;

export const selectCurrentChat = ({anonymousChat: {currentChat}}) => currentChat;
export const selectChats = ({anonymousChat: {chats}}) => chats;
export const selectChatsTotal = ({anonymousChat: {chatsTotal}}) => chatsTotal;
export const selectMessagesTotal = ({anonymousChat: {messagesTotal}}) => messagesTotal;
export const selectCategories = ({anonymousChat: {categories}}) => categories;
export const selectFilter = ({anonymousChat: {filter}}) => filter;
export const selectIsFetched = ({anonymousChat: {isFetched}}) => isFetched;
export const selectUpdatingChatId = ({anonymousChat: {updatingChatId}}) => updatingChatId;
export const selectUpdatingMessageId = ({anonymousChat: {updatingMessageId}}) => updatingMessageId;
export const selectUpdatingCategoriesChatId = ({anonymousChat: {updatingCategoriesChatId}}) => updatingCategoriesChatId;

export const fetchData = async filter => {

    dispatch(slice.actions.setIsFetched(false));

    getChats(filter)
        .then(({chats, chatsTotal, messagesTotal}) => {

            dispatch(slice.actions.setChats(chats));
            dispatch(slice.actions.setChatsTotal(chatsTotal));
            dispatch(slice.actions.setMessagesTotal(messagesTotal));
            dispatch(slice.actions.setIsFetched(true));
        })
        .catch(error => {

            console.log('fetchData getLawCases', {error});

            dispatch(slice.actions.setIsFetched(true));
        });

    getRegionsAndCategories()
        .then(({regions, categories}) => {
            dispatch(slice.actions.setCategories(categories));
        });
};

export const blockChat = ({chatId, blocked}) => {

    dispatch(slice.actions.setUpdatingChatId(chatId));

    blockChatApi({chatId, blocked})
        .then(({chat}) => {

            dispatch(slice.actions.setCurrentChat(chat));

            dispatch(slice.actions.updateChat(chat));

            dispatch(slice.actions.setUpdatingChatId(null));
        })
        .catch(error => {

            console.log({error});

            dispatch(slice.actions.setUpdatingChatId(null));
        });
};

export const blockMessage = ({messageId, blocked}) => {

    dispatch(slice.actions.setUpdatingMessageId(messageId));

    blockMessageApi({messageId, blocked})
        .then(({chat}) => {

            dispatch(slice.actions.setCurrentChat(chat));

            dispatch(slice.actions.updateChat(chat));

            dispatch(slice.actions.setUpdatingMessageId(null));
        })
        .catch(error => {

            console.log({error});

            dispatch(slice.actions.setUpdatingMessageId(null));
        });
};

export const updateCategories = ({chatId, categoriesId}) => {

    dispatch(slice.actions.setUpdatingCategoriesChatId(chatId));

    setCategoriesApi({chatId, categoriesId})
        .then(({chat}) => {

            dispatch(slice.actions.setCurrentChat(chat));

            dispatch(slice.actions.updateChat(chat));

            dispatch(slice.actions.setUpdatingCategoriesChatId(null));
        })
        .catch(error => {

            console.log({error});

            dispatch(slice.actions.setUpdatingCategoriesChatId(null));
        });
};
