import {useTheme} from '@mui/material';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import LockClockRoundedIcon from '@mui/icons-material/LockClockRounded';
import Avatar from '../../../ui-component/extended/Avatar';
import {
    STAGE_COMPLETED_STATUS,
    STAGE_WAIT_STATUS
} from '../../../store/slices/lawCase';

function getAvatarBackgroundColor({status, theme}) {

    if (status === STAGE_COMPLETED_STATUS)
        return theme.palette.secondary[200];

    if (status === STAGE_WAIT_STATUS)
        return theme.palette.grey[300];

    return theme.palette.primary[400];
}

function getAvatarIconColor({status, theme}) {

    if (status === STAGE_COMPLETED_STATUS)
        return theme.palette.secondary.main;

    if (status === STAGE_WAIT_STATUS)
        return theme.palette.grey[500];

    return theme.palette.primary.main;
}

function Icon({status}) {

    const theme = useTheme();

    const sx = {
        width: 24,
        height: 24,
        color: getAvatarIconColor({status, theme}),
    };

    if (status === STAGE_COMPLETED_STATUS)
        return <DoneRoundedIcon {...{sx}}/>;

    if (status === STAGE_WAIT_STATUS)
        return <LockClockRoundedIcon {...{sx}}/>;

    return <ForumRoundedIcon {...{sx}}/>;
}

export default function StageAvatar({status}) {

    const theme = useTheme();

    return (
        <Avatar
            sx={{
                width: 30,
                height: 30,
                bgcolor: getAvatarBackgroundColor({status, theme})
            }}
        >
            <Icon status={status}/>
        </Avatar>
    );
}
