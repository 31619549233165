// third-party
import {FormattedMessage} from 'react-intl';

// assets
import {IconId, IconMessage2, IconUsers, IconBriefcase, IconUserCircle, IconAlertCircle} from '@tabler/icons';
import urls from '../utils/urls';
// import {useSelector} from '../store';
// import {selectWithOneTimeConsultationPrivateChatType} from '../store/slices/notification';
// import {Chip} from '@mui/material';

// constant
const icons = {
    IconId,
    IconMessage2,
    IconUsers,
    IconBriefcase,
    IconUserCircle,
    IconAlertCircle,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

// const ChipCount = ({color = 'error', size = 'small', label}) => (
//     <Chip
//         color={color}
//         size={size}
//         label={<FormattedMessage id={label} />}
//     />
// );

// const OntTimeConsultationPrivateChatNotificationCount = () => {
//
//     const notifications = useSelector(selectWithOneTimeConsultationPrivateChatType);
//
//     // eslint-disable-next-line no-nested-ternary
//     const count = notifications
//         ? notifications.length > 0
//             ? notifications.length
//             : null
//         : null
//     ;
//
//     if (!count)
//         return null;
//
//
//     return (
//         <ChipCount
//             label={count}
//         />
//     );
// };

const admin = {
    id: 'admin',
    type: 'group',
    children: [
        {
            id: urls.dashboard,
            title: <FormattedMessage id="cases" defaultMessage="Юристы" />,
            type: 'item',
            url: urls.dashboard,
            icon: icons.IconUsers,
            breadcrumbs: false,
        },
        {
            id: urls.cases,
            title: <FormattedMessage id="cases" defaultMessage="Спорные ституации" />,
            type: 'item',
            url: urls.newCases,
            icon: icons.IconAlertCircle,
            breadcrumbs: false,
        },
        {
            id: urls.anonymousChats,
            title: <FormattedMessage id="cases" defaultMessage="Анонимные чаты" />,
            type: 'item',
            url: urls.anonymousChats,
            icon: icons.IconMessage2,
            breadcrumbs: false,
        },
    ]
};

export default admin;
