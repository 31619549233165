export default function getFormatRuDate({
    date = new Date(),
    withTime = false,
    hideCurrentYear = false,
    timeIsFirst = false,
    hideCurrentDayAndMonth = false,
    showSeconds = false,
}) {

    const parts = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    };
    const formattedParts = {};
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const currentDay = currentDate.getDate();

    // eslint-disable-next-line no-restricted-syntax
    for (const key in parts) {

        if (!parts.hasOwnProperty(key))
        // eslint-disable-next-line no-continue
            continue;

        const part = parts[key];

        formattedParts[key] = part < 10 ? `0${part}` : `${part}`;
    }

    const yearPart = hideCurrentYear && currentYear === parts.year ? '' : `.${formattedParts.year}`;
    const timePart = withTime ? `${formattedParts.hours}:${formattedParts.minutes}${showSeconds ? `:${formattedParts.seconds}` : ''}` : '';

    if (timeIsFirst) {

        if (hideCurrentDayAndMonth && currentMonth === parts.month && currentDay === parts.day)
            return `${timePart}`.trim();

        return `${timePart} ${formattedParts.day}.${formattedParts.month}${yearPart}`.trim();
    }

    if (hideCurrentDayAndMonth && currentMonth === parts.month && currentDay === parts.day)
        return `${timePart}`.trim();

    return `${formattedParts.day}.${formattedParts.month}${yearPart} ${timePart}`.trim();

}
