import {Grid, TextField, useMediaQuery, useTheme} from '@mui/material';
import {
    COMPLETED_STATUS as STAGE_COMPLETED_STATUS,
    WAIT_STATUS as STAGE_WAIT_STATUS
} from '../../../store/slices/stage';
import {useState} from 'react';
import completeLawCase from '../../../api/lawCase/completeLawCase';
import {gridSpacing} from '../../../store/constant';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip/Tooltip';
import IconButton from '@mui/material/IconButton';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ImportExportRoundedIcon from '@mui/icons-material/ImportExportRounded';
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import {LoadingButton} from '@mui/lab';
import Currency from '../../../ui-component/Currency';
import getNumeralDeclinationWord from '../../../utils/getNumeralDeclinationWord';

function Cost({text, cost}) {

    return <>{text} <b><Currency value={cost}/>&nbsp;{getNumeralDeclinationWord(cost, ['рубль', 'рубля', 'рублей'])}</b></>;
}

function ActiveLawCase({lawCase}) {

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const {stages} = lawCase;
    const lawCaseCost = stages.map(({cost}) => cost).reduce((a, b) => a + b, 0);
    const lawCasePaidOutBalance = stages.filter(({status}) => status === STAGE_COMPLETED_STATUS && status !== STAGE_WAIT_STATUS).map(({balance}) => balance).reduce((a, b) => a + b, 0);
    const lawCaseFreeBalance = stages.filter(({status}) => status !== STAGE_COMPLETED_STATUS && status !== STAGE_WAIT_STATUS).map(({balance}) => balance).reduce((a, b) => a + b, 0);
    const [loading, setLoading] = useState(false);
    const [lawyerCost, setLawyerCost] = useState(lawCaseFreeBalance);
    const [customerCost, setCustomerCost] = useState(0);

    const onSwitchClick = e => {

        e.preventDefault();

        const newLawyerCost = customerCost;

        setCustomerCost(lawyerCost);

        setLawyerCost(newLawyerCost);
    };

    const onLayerCostClick = e => {

        e.preventDefault();

        setCustomerCost(0);

        setLawyerCost(lawCaseFreeBalance);
    };

    const onCustomerCostClick = e => {

        e.preventDefault();

        setLawyerCost(0);

        setCustomerCost(lawCaseFreeBalance);
    };

    const onLawyerCostChange = e => {

        const value = e.target.value;

        if (value > lawCaseFreeBalance) {

            setLawyerCost(lawCaseFreeBalance);

            setCustomerCost(0);

        } else if (value < 0) {

            setLawyerCost(0);

            setCustomerCost(lawCaseFreeBalance);

        } else {

            setLawyerCost(value);

            setCustomerCost(lawCaseFreeBalance - value);
        }
    };

    const onCustomerCostChange = e => {

        const value = e.target.value;

        if (value > lawCaseFreeBalance) {

            setCustomerCost(lawCaseFreeBalance);

            setLawyerCost(0);

        } else if (value < 0) {

            setCustomerCost(0);

            setLawyerCost(lawCaseFreeBalance);

        } else {

            setCustomerCost(value);

            setLawyerCost(lawCaseFreeBalance - value);
        }
    };

    const onClick = e => {

        e.preventDefault();

        setLoading(true);

        completeLawCase({lawCaseId: lawCase.id, costForCustomer: customerCost, costForLawyer: lawyerCost})
            .then(response => {

                console.log(response);
            })
            .catch(error => {

                console.log({error});
            })
            .then(() => {

                setLoading(false);
            });
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Cost text="Общая стоимость дела:" cost={lawCaseCost} />
            </Grid>

            <Grid item xs={12}>
                <Cost text="Баланс &quot;Безопасной сделки&quot;:" cost={lawCaseFreeBalance} />
            </Grid>

            <Box sx={{width: '100%', pb: 1}} />

            <Grid item xs={12} md={5}>
                <TextField
                    size="small"
                    label="Перечислить юристу"
                    fullWidth
                    value={lawyerCost}
                    onChange={onLawyerCostChange}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Перевести всё юристу">
                                    <IconButton size="small" color="primary" onClick={onLayerCostClick} edge="end">
                                        <ArrowCircleLeftRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                    }}
                />
            </Grid>

            <Grid item xs={12} md="auto">
                <Box sx={{textAlign: 'center'}}>
                    <IconButton color="primary" size="small" onClick={onSwitchClick}>
                        {
                            matchDownMd
                                ? <ImportExportRoundedIcon />
                                : <SyncAltRoundedIcon />
                        }
                    </IconButton>
                </Box>
            </Grid>

            <Grid item xs={12} md={5}>
                <TextField
                    size="small"
                    label="Перечислить заказчику"
                    fullWidth
                    value={customerCost}
                    onChange={onCustomerCostChange}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <Tooltip title="Перевести всё заказчику">
                                    <IconButton size="small" color="primary" onClick={onCustomerCostClick} edge="end">
                                        <ArrowCircleLeftRoundedIcon />
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                    }}
                />
            </Grid>

            <Box sx={{width: '100%', pb: 1}} />

            <Grid item xs={12}>
                <LoadingButton
                    variant="contained"
                    disableElevation
                    size="small"
                    onClick={onClick}
                    disabled={loading || lawCaseFreeBalance <= 0}
                    loading={loading}
                >
                    Закрыть дело
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default ActiveLawCase;
