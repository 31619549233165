import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import Api from './utils/api';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    getPrivateChats, selectChatCreating,
    selectChats, selectCurrentChat,
    selectFilter,
    setChats,
    setConnection, setCurrentChat,
    setUserId
} from '../../store/slices/chat';
import useAuth from '../../hooks/useAuth';
import {isAddPrivateMessageRoute} from './api/addPrivateMessage';
import {isGetPrivateChatsRoute} from './api/getPrivateChats';
import formatChats from './utils/formatChats';
import {fetchData as fetchLawCaseData} from '../../store/slices/lawCase';

export const privateChatConnection = new Api(process.env.REACT_APP_WS_CONSULTATIONS_URL);
privateChatConnection.connect();

function Provider({children}) {

    const dispatch = useDispatch();
    const filter = useSelector(selectFilter);
    const chats = useSelector(selectChats);
    const {isLoggedIn} = useAuth();
    const chatCreating = useSelector(selectChatCreating);
    const currentChat = useSelector(selectCurrentChat);

    const {privateChatId: chatIdParam} = useParams();

    const onConnectionOpen = () => {

        if (isLoggedIn)
            getPrivateChats();
    };

    const onConnectionMessage = (data) => {

        if (data.hasOwnProperty('userId'))
            dispatch(setUserId(data.userId));

        if (data.hasOwnProperty('chats') && data.chats)
            dispatch(setChats(formatChats(data.chats)));

        if (data.hasOwnProperty('wasUpdated') && data.wasUpdated)
            getPrivateChats();

        if (data.hasOwnProperty('action') && data.action) {

            if (isAddPrivateMessageRoute(data.action))
                getPrivateChats();

            if (isGetPrivateChatsRoute(data.action)) {

                fetchLawCaseData();

                if (data.chats) {

                    const formattedChats = formatChats(data.chats);

                    dispatch(setChats(formattedChats));

                    if (chatIdParam) {

                        dispatch(setCurrentChat(formattedChats.find(({id}) => id === chatIdParam)));
                    }
                }


            }
        }
    };

    useEffect(() => {

        dispatch(setConnection(privateChatConnection));

        privateChatConnection.on('open', onConnectionOpen);
        privateChatConnection.on('message', onConnectionMessage);

        if (chats !== null && chatIdParam) {

            const currentChat = chats.find(({id}) => id === chatIdParam);

            if (currentChat)
                dispatch(setCurrentChat(currentChat));
        }

        return () => {

            privateChatConnection.off('open', onConnectionOpen);
            privateChatConnection.off('message', onConnectionMessage);
        };

    }, [filter, privateChatConnection, chats, chatCreating, privateChatConnection.connection.readyState, chatIdParam]);

    useEffect(() => {

        if (chats !== null && Array.isArray(chats) && currentChat) {

            const newCurrentChat = chats.find(({id}) => id === currentChat.id);

            if (newCurrentChat)
                dispatch(setCurrentChat(newCurrentChat))
        }

    }, [chats]);

    useEffect(() => {

        if (chatIdParam && chats !== null && Array.isArray(chats) && currentChat !== null) {

            const newCurrentChat = chats.find(({id}) => id === chatIdParam);

            if (newCurrentChat)
                dispatch(setCurrentChat(newCurrentChat))
        }

    }, [chatIdParam]);

    useEffect(() => {

        if (isLoggedIn && privateChatConnection)
            getPrivateChats(privateChatConnection);

    }, [isLoggedIn, privateChatConnection]);

    return children;
}

Provider.propTypes = {
    children: PropTypes.node
};

export default Provider;



// import {useLocation, useNavigate, useParams} from 'react-router-dom';
// import PropTypes from 'prop-types';
// import Api from '../Chat/features/utils/api';
// import {useEffect, useState} from 'react';
// import {useDispatch, useSelector} from 'react-redux';
// import {
//     getPrivateChatsFromAnon, getPrivateChatsFromCustomer,
//     getPrivateChatsFromLawyer, selectChatCreating,
//     selectChats, selectCurrentChat,
//     selectFilter,
//     setChatCreating,
//     setChats,
//     setConnection, setCreateLink, setCurrentChat,
//     setUserId,
// } from '../../store/slices/chat';
// import urls from '../../utils/urls';
// import useAuth from '../../hooks/useAuth';
// import createPrivateChatHttp from './api/createPrivateChatWithLawyerHttp';
// import {isAddPrivateMessageRoute} from './api/addPrivateMessage';
// import {isGetPrivateChatsRoute} from './api/getPrivateChats';
// import formatChats from './utils/formatChats';
//
// export const privateChatConnection = new Api(process.env.REACT_APP_WS_CONSULTATIONS_URL);
// privateChatConnection.connect();
//
// function Provider({children}) {
//
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const filter = useSelector(selectFilter);
//     const chats = useSelector(selectChats);
//     const {isLoggedIn, isLawyer, isClient} = useAuth();
//     const chatCreating = useSelector(selectChatCreating);
//     const currentChat = useSelector(selectCurrentChat);
//     const authToken = localStorage.getItem('chat-token');
//     const [isInit, setIsInit] = useState(false);
//
//     const _isLawyer = isLawyer();
//     const _isClient = isClient();
//
//     const {privateChatId: chatIdParam} = useParams();
//
//     const {withMember, withChat} = useParams();
//
//     const {pathname} = useLocation();
//
//     const [connectionIsOpen, setConnectionIsOpen] = useState();
//
//     const requestPrivateChats = () => {
//
//         if (_isClient || _isLawyer) {
//
//             if (_isLawyer)
//                 getPrivateChatsFromLawyer();
//
//             else if (_isClient)
//                 getPrivateChatsFromCustomer();
//
//             else
//                 getPrivateChatsFromAnon();
//         }
//     };
//
//     useEffect(() => {
//
//         if (_isClient)
//             dispatch(setCreateLink(urls.customerCreateConsultationWizard));
//
//     }, [dispatch, _isClient]);
//
//     useEffect(() => {
//
//         if (authToken) {
//
//             if (pathname.includes(urls.customerCreateConsultationChat)) {
//
//                 if (!withMember) {
//
//                     if (_isClient)
//                         navigate(urls.customerConsultationsChats);
//
//                     if (_isLawyer)
//                         navigate(urls.lawyerConsultationsChats);
//
//                 } else {
//
//                     dispatch(setChatCreating(true));
//
//                     createPrivateChatHttp({lawyerMemberId: withMember, publicChatId: withChat})
//                         .then(response => {
//
//                             if (window.hasOwnProperty('ym') && typeof window.ym === 'function')
//                                 window.ym(76373581, 'reachGoal', 'sozdanie_lichnogo_chata');
//
//                             if (response.data.hasOwnProperty('chatId') && response.data.chatId) {
//
//                                 if (_isLawyer)
//                                     navigate(`${urls.lawyerConsultationsChat}/${response.data.chatId}`);
//
//                                 if (_isClient)
//                                     navigate(`${urls.customerConsultationsChat}/${response.data.chatId}`);
//
//                                 const currentChat = response.data.chatId
//                                     ? chats.find(({id}) => id === response.data.chatId)
//                                     : null;
//
//                                 if (currentChat)
//                                     dispatch(setCurrentChat(currentChat));
//                             }
//                         })
//                         .catch(error => {
//                             console.log({error});
//                         })
//                         .then(() => {
//
//                             dispatch(setChatCreating(false));
//                         })
//                     ;
//                 }
//             }
//         }
//
//     }, [pathname, withMember, isLoggedIn, _isLawyer, _isClient, authToken]);
//
//     const onConnectionOpen = () => {
//
//         console.log('onConnectionOpen requestPrivateChats');
//         requestPrivateChats();
//
//         setConnectionIsOpen(true);
//     };
//
//     const onConnectionMessage = (data) => {
//
//         if (data.hasOwnProperty('chats') && data.chats)
//             dispatch(setChats(formatChats(data.chats)));
//
//         if (data.hasOwnProperty('wasUpdated') && data.wasUpdated) {
//
//             requestPrivateChats();
//         }
//
//         if (data.hasOwnProperty('action') && data.action) {
//
//             if (data.hasOwnProperty('userId'))
//                 dispatch(setUserId(data.userId));
//
//             // requestPrivateChats();
//
//             if (isAddPrivateMessageRoute(data.action))
//                 requestPrivateChats();
//
//             if (isGetPrivateChatsRoute(data.action)) {
//
//                 if (data.chats) {
//
//                     const formattedChats = formatChats(data.chats);
//
//                     dispatch(setChats(formattedChats));
//
//                     if (chatIdParam)
//                         dispatch(setCurrentChat(formattedChats.find(({id}) => id === chatIdParam)));
//                 }
//             }
//         }
//     };
//
//     const onConnectionFailure = () => {
//
//         setConnectionIsOpen(false);
//     };
//
//     useEffect(() => {
//
//         dispatch(setConnection(privateChatConnection));
//
//         privateChatConnection.on('open', onConnectionOpen);
//         privateChatConnection.on('message', onConnectionMessage);
//         privateChatConnection.on('error', onConnectionFailure);
//         privateChatConnection.on('close', onConnectionFailure);
//
//         if (chats !== null && chatIdParam) {
//
//             const currentChat = chats.find(({id}) => id === chatIdParam);
//
//             if (currentChat)
//                 dispatch(setCurrentChat(currentChat));
//         }
//
//         return () => {
//
//             privateChatConnection.off('open', onConnectionOpen);
//             privateChatConnection.off('message', onConnectionMessage);
//             privateChatConnection.off('error', onConnectionFailure);
//             privateChatConnection.off('close', onConnectionFailure);
//         };
//
//     }, [filter, privateChatConnection, chats, chatCreating, connectionIsOpen, privateChatConnection.connection.readyState, chatIdParam, isInit, _isClient, _isLawyer]);
//
//     useEffect(() => {
//
//         if (chatIdParam && Array.isArray(chats)) {
//
//             const newCurrentChat = chats.find(({id}) => id === chatIdParam);
//
//             if (newCurrentChat)
//                 dispatch(setCurrentChat(newCurrentChat))
//         }
//
//     }, [chatIdParam, chats]);
//
//     useEffect(() => {
//
//         if (!isInit) {
//
//             requestPrivateChats();
//
//             if (_isClient || _isLawyer)
//                 setIsInit(true);
//         }
//     }, [isInit, _isClient, _isLawyer, requestPrivateChats]);
//
//     return (
//         <>{children}</>
//     );
// }
//
// Provider.propTypes = {
//     children: PropTypes.node
// };
//
// export default Provider;
