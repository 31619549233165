// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FIREBASE_STATE_CHANGED = 'FIREBASE_STATE_CHANGED';

export const PHONE_REQUEST = 'PHONE_REQUEST';
export const PHONE_CONFIRM = 'PHONE_CONFIRM';

export const REDIRECT_AFTER_LOGIN = 'REDIRECT_AFTER_LOGIN';
