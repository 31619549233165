import {lazy} from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import urls from '../utils/urls';

// login routing
// const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/Auth')));
const EmailAndEmailCodeAndOTP = Loadable(lazy(() => import('views/pages/authentication/EmailAndEmailCodeAndOTP')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: urls.root,
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: urls.signIn,
            element: <EmailAndEmailCodeAndOTP />
        },
    ]
};

export default LoginRoutes;
