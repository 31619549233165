// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar, Box} from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import {useDispatch, useSelector} from 'store';
import {openDrawer} from 'store/slices/menu';

// assets
import {IconMenu2} from '@tabler/icons';
import useAuth from '../../../hooks/useAuth';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({withSidebar = true}) => {
    const theme = useTheme();

    const dispatch = useDispatch();
    const {drawerOpen} = useSelector((state) => state.menu);

    const {isLoggedIn} = useAuth();

    return (
        <>
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{display: {xs: 'none', md: 'block'}, flexGrow: 1}}>
                    <LogoSection />
                </Box>

                {
                    withSidebar &&
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            overflow: 'hidden',
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.primary.main : theme.palette.primary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.primary.light : theme.palette.primary.light
                            }
                        }}
                        onClick={() => dispatch(openDrawer(!drawerOpen))}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem"/>
                    </Avatar>
                }
            </Box>
            <Box sx={{flexGrow: 1}} />
            <Box sx={{flexGrow: 1}} />
            <NotificationSection />
            {
                isLoggedIn &&
                <ProfileSection />
            }
        </>
    );
};

export default Header;
