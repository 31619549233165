import {useLocation} from 'react-router-dom';
import TabContext from '@mui/lab/TabContext';
import Grid from '@mui/material/Grid';
import Tabs from './Tabs';
import urls from '../../../../utils/urls';
import Content from './Content';
import {useSelector} from '../../../../store';
import {
    CONTROVERSIAL_SITUATION_STATUS,
    CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS,
    selectStages
} from '../../../../store/slices/stage';

function ControversialStagesList() {

    const location = useLocation();
    const stages = useSelector(selectStages);
    const controversialStages = stages ? stages.filter(stage => stage.status === CONTROVERSIAL_SITUATION_STATUS) : [];
    const controversialWithJurscannerStages = stages ? stages.filter(stage => stage.status === CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS) : [];

    return (
        <TabContext value={location.pathname}>
            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                sx={{
                    height: '100%',
                }}
            >
                <Grid item>
                    <Tabs/>
                </Grid>
                <Grid
                    item
                    xs
                    sx={{
                        flex: '1 1 auto'
                    }}
                >
                    <Content
                        key={urls.controversialChats}
                        items={controversialWithJurscannerStages}
                        value={urls.controversialChats}
                    />
                    <Content
                        key={urls.controversialChatsNew}
                        items={controversialStages}
                        value={urls.controversialChatsNew}
                    />
                </Grid>
            </Grid>
        </TabContext>
    );
}

export default ControversialStagesList;
