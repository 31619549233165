import '../utils/api';

export const ROUTE = '/admin/getPrivateChats';

/**
 * @param {Api} connection
 */
export default function getPrivateChats(connection) {

    connection.send({action: ROUTE, memberType: 'SUPER_TYPE'});
}

export const isGetPrivateChatsRoute = route => ROUTE === route;
