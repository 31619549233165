import api from './axios';

export default function postRequest(url, data = {}) {

    return new Promise((resolve, reject) => {

        api.post(url, data)
            .then(response => {

                console.log('resetPasswordByEmailAndPassword response', response);

                resolve(response.data);
            })
            .catch(error => {

                console.log('resetPasswordByEmailAndPassword error', error);

                reject(error);
            })
            .then(() => {

                reject();
            });
    });
}
