export default function truncateString(str = '', {length = 20, separator = '...'} = {length: 20, separator: '...'}) {

    if (str.length + separator.length <= length)
        return str;

    const charsToShow = length - separator.length;
    const frontChars = Math.ceil(charsToShow/2);
    const backChars = Math.floor(charsToShow/2);

    return str.substr(0, frontChars) + separator + str.substr(str.length - backChars);
}
