import {useState, useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Box,
    Button, CardContent, Grid,
    styled,
    useMediaQuery,
    useTheme
} from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import MainCard from '../../../ui-component/cards/MainCard';
import {appDrawerWidth as drawerWidth, gridSpacing} from '../../../store/constant';
import Drawer from './Drawer';
import Header from './Header';
import Field from '../Field';

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    flexGrow: 1,
    paddingLeft: open ? theme.spacing(3) : 0,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter
    }),
    marginLeft: `-${drawerWidth}px`,
    [theme.breakpoints.down('lg')]: {
        paddingLeft: 0,
        marginLeft: 0
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.shorter
        }),
        marginLeft: 0
    })
}));

export const SETTING_STAGE_VIEW = 'SETTING_STAGE_VIEW';
export const CHAT_STAGE_VIEW = 'CHAT_STAGE_VIEW';

function Layout({
    backUrl,
    drawer,
    drawerHeader,
    drawerFooter,
    headerButton,
    avatar,
    avatars,
    nickname,
    icon,
    message,
    setMessage,
    handleOnSend,
    children,
    stageSettings,
}) {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('lg'));
    const {stageId: stageIdParam} = useParams();
    const [openDrawer, setOpenDrawer] = useState(true);
    const [stageView, setStageView] = useState(stageIdParam ? SETTING_STAGE_VIEW : CHAT_STAGE_VIEW);

    const handleDrawerOpen = () => {

        setOpenDrawer((prevState) => !prevState);
    };

    useEffect(() => {

        setOpenDrawer(!matchDownSM);

    }, [matchDownSM]);

    useEffect(() => {

        setStageView(stageIdParam ? SETTING_STAGE_VIEW : CHAT_STAGE_VIEW);

    }, [stageIdParam]);

    return (
        <Box sx={{height: '100%', display: 'flex', flexFlow: 'column nowrap',}}>
            <Box sx={{pt: '6px', pb: '10px', flexShrink: 1, flexGrow: 0,}}>
                <Button
                    component={Link}
                    to={backUrl}
                    variant="text"
                    startIcon={<ArrowBackIosNewRoundedIcon/>}
                >
                    Назад
                </Button>
            </Box>

            <Box sx={{display: 'flex', flexShrink: 1, flexGrow: 1,}}>
                {
                    drawer &&
                    <Drawer
                        handleDrawerOpen={handleDrawerOpen}
                        openDrawer={openDrawer}
                        header={drawerHeader}
                        footer={drawerFooter}
                    >
                        {drawer}
                    </Drawer>
                }
                <Main theme={theme} open={openDrawer}>
                    <Grid
                        container
                        spacing={gridSpacing}
                        justifyContent={drawer ? 'flex-start' : 'center'}
                        sx={{height: '100%'}}
                    >
                        <Grid
                            item
                            xs
                            zeroMinWidth
                            sx={{display: 'flex', height: '100%'}}
                        >
                            <MainCard
                                sx={{
                                    backgroundColor: theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50',
                                    maxWidth: '650px',
                                    flexGrow: 1,
                                    height: 'calc(100% + 24px)',
                                }}
                                contentSX={{
                                    display: 'flex',
                                    flexFlow: 'column nowrap',
                                    height: '100%',
                                    px: {
                                        xs: 2,
                                        sm: 3,
                                    },
                                }}
                            >
                                <Grid container spacing={gridSpacing} sx={{flexShrink: 0, flexGrow: 0}}>
                                    <Grid item xs={12}>
                                        <Header
                                            showDrawerOpen={drawer}
                                            handleDrawerOpen={handleDrawerOpen}
                                            avatar={avatar}
                                            avatars={avatars}
                                            nickname={nickname}
                                            icon={icon}
                                            headerButton={headerButton}
                                            stageView={stageView}
                                            setStageView={setStageView}
                                        />
                                    </Grid>
                                </Grid>

                                <PerfectScrollbar
                                    style={{
                                        width: '100%',
                                        height: 'calc(100vh - 440px)',
                                        minHeight: '150px',
                                        overflowX: 'hidden',
                                        flexShrink: 1,
                                        flexGrow: 1,
                                    }}
                                >
                                    <CardContent sx={{pl: 0, pr: 2}}>
                                        {
                                            stageView === CHAT_STAGE_VIEW &&
                                            children
                                        }
                                        {
                                            stageView === SETTING_STAGE_VIEW &&
                                            stageSettings
                                        }
                                    </CardContent>
                                </PerfectScrollbar>

                                {
                                    stageView === CHAT_STAGE_VIEW &&
                                    <Box xs={12} sx={{flexShrink: 0, flexGrow: 0, pt: {xs: 1.5, sm: 3}, mr: {xs: -1.5, sm: -1.5}}}>
                                        <Field
                                            message={message}
                                            setMessage={setMessage}
                                            handleOnSend={handleOnSend}
                                        />
                                    </Box>
                                }
                            </MainCard>
                        </Grid>
                    </Grid>
                </Main>
            </Box>
        </Box>
    );
}

export default Layout;
