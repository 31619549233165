// third-party
import {createSlice, current} from '@reduxjs/toolkit';
// project imports
import {dispatch} from '../index';
// import getNotifications from '../../api/notification/getNotifications';
// import viewNotificationsHttp from '../../api/notification/viewNotificationsHttp';

/*
Notification = {
    string id,
    ?string title,
    ?string avatar,
    ?string content,
    ?Date date,
}
 */

const LAWYER_NEW_PRIVATE_CHAT_TYPE = 'LAWYER_NEW_PRIVATE_CHAT_TYPE';
const ADD_LAW_CASE_FOR_LAWYER_TYPE = 'ADD_LAW_CASE_FOR_LAWYER_TYPE';
const CUSTOMER_NEW_ONE_TIME_CONSULTATION_PRIVATE_CHAT_TYPE = 'CUSTOMER_NEW_ONE_TIME_CONSULTATION_PRIVATE_CHAT_TYPE';
const NEW_LAWYER_CHAT_CATEGORY_TYPE = 'NEW_LAWYER_CHAT_CATEGORY_TYPE';
const LAWYER_EMPTY_EMAIL_TYPE = 'LAWYER_EMPTY_EMAIL_TYPE';
const LAWYERS_SELECTED_COSTS_FOR_CUSTOMER_TYPE = 'LAWYERS_SELECTED_COSTS_FOR_CUSTOMER_TYPE';
const ADD_LAW_CASE_AFTER_CUSTOMER_SIGN_UP = 'ADD_LAW_CASE_AFTER_CUSTOMER_SIGN_UP';
const ADD_ANON_CHAT_MESSAGE = 'ADD_ANON_CHAT_MESSAGE';
const LAWYER_ACTIVATION_TYPE = 'LAWYER_ACTIVATION_TYPE';
const LAWYER_DEACTIVATION_TYPE = 'LAWYER_DEACTIVATION_TYPE';
const ADD_CONSULTATION_MESSAGE_TYPE = 'ADD_CONSULTATION_MESSAGE_TYPE';

export const isLawyersSelectedCostsForCustomerType = type => type === LAWYERS_SELECTED_COSTS_FOR_CUSTOMER_TYPE;
export const isAddLawCaseAfterCustomerSignUpType = type => type === ADD_LAW_CASE_AFTER_CUSTOMER_SIGN_UP;
export const isNewPrivateChatType = type => type === LAWYER_NEW_PRIVATE_CHAT_TYPE;
export const isAddLawCaseForLawyerType = type => type === ADD_LAW_CASE_FOR_LAWYER_TYPE;
export const isCustomerNewOneTimeConsultationPrivateChatType = type => type === CUSTOMER_NEW_ONE_TIME_CONSULTATION_PRIVATE_CHAT_TYPE;
export const isNewLawyerChatCategoryType = type => type === NEW_LAWYER_CHAT_CATEGORY_TYPE;
export const isLawyerEmptyEmailType = type => type === LAWYER_EMPTY_EMAIL_TYPE;
export const isAddAnonChatMessage = type => type === ADD_ANON_CHAT_MESSAGE;
export const isLawyerActivationType = type => type === LAWYER_ACTIVATION_TYPE;
export const isLawyerDeactivationType = type => type === LAWYER_DEACTIVATION_TYPE;
export const isAddConsultationMessageType = type => type === ADD_CONSULTATION_MESSAGE_TYPE;

const initialState = {
    notifications: null,
    connection: null,
    isFetching: null,
};

const slice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setIsFetching: (state, {payload}) => {state.isFetching = payload;},
        setConnection: (state, {payload}) => {state.connection = payload;},
        addNotification: (state, {payload}) => {

            if (!state.notifications.find(notification => notification.id === payload.id))
                state.notification = payload;
        },
        removeNotification: (state, {payload}) => {

            state.notifications = [...state.notifications]
                .map(notification => ({...notification}))
                .filter(({id}) => payload.indexOf(id) === -1);
        },
        setNotifications: (state, {payload}) => {

            const oldNotifications = current(state).notifications;

            if (oldNotifications) {

                const lastOldLawyerActivationNotification = oldNotifications.find(({type}) => isLawyerActivationType(type));
                const lastNewLawyerActivationNotification = payload.find(({type}) => isLawyerActivationType(type));
                const lastOldLawyerDeactivationNotification = oldNotifications.find(({type}) => isLawyerDeactivationType(type));
                const lastNewLawyerDeactivationNotification = payload.find(({type}) => isLawyerDeactivationType(type));

                if ((lastNewLawyerActivationNotification && !lastOldLawyerActivationNotification)
                || (lastOldLawyerActivationNotification && lastNewLawyerActivationNotification && lastOldLawyerActivationNotification.id !== lastNewLawyerActivationNotification.id)
                || (lastNewLawyerDeactivationNotification && !lastOldLawyerDeactivationNotification)
                || (lastOldLawyerDeactivationNotification && lastNewLawyerDeactivationNotification && lastOldLawyerDeactivationNotification.id !== lastNewLawyerDeactivationNotification.id))
                // eslint-disable-next-line no-self-assign
                    window.location.href = window.location.href;
            }

            state.notifications = payload;
        },
    },
});

export const {
    setConnection,
    addNotification,
    removeNotification,
    setNotifications,
} = slice.actions;

// Reducer
export default slice.reducer;

export const selectNotifications = ({notification: {notifications}}) => notifications;
export const selectIsFetching = ({notification: {isFetching}}) => isFetching;
export const selectWithNewPrivateChatType = ({notification: {notifications}}) => Array.isArray(notifications)
    ? notifications.filter(({type}) => isNewPrivateChatType(type))
    : [];
export const selectLawyersSelectedCostsForCustomerTypeList = ({notification: {notifications}}) => {

    if (!Array.isArray(notifications))
        return [];

    const uniqNotificationIds = [];

    for (let i = 0; i < notifications.length; i++) {

        const notification = notifications[i];

        if (notification.hasOwnProperty('notificationData')
        && notification.notificationData
        && notification.notificationData.hasOwnProperty('lawCaseId')
        && !uniqNotificationIds.includes(notification.notificationData.lawCaseId))
            uniqNotificationIds.push(notification.notificationData.lawCaseId);
    }

    return uniqNotificationIds;
};
export const selectAddLawCaseForLawyerTypeList = ({notification: {notifications}}) => {

    if (!Array.isArray(notifications))
        return [];

    const uniqNotificationIds = [];

    for (let i = 0; i < notifications.length; i++) {

        const notification = notifications[i];

        if (notification.hasOwnProperty('notificationData')
        && notification.notificationData
        && notification.notificationData.hasOwnProperty('lawCaseId')
        && !uniqNotificationIds.includes(notification.notificationData.lawCaseId))
            uniqNotificationIds.push(notification.notificationData.lawCaseId);
    }

    return uniqNotificationIds;
};

export const selectWithNewLawyerChatCategoryType = ({notification: {notifications}}) => Array.isArray(notifications)
    ? notifications.filter(({type}) => isNewLawyerChatCategoryType(type))
    : [];

export const selectWithOneTimeConsultationPrivateChatType = ({notification: {notifications}}) => Array.isArray(notifications)
    ? notifications.filter(({type}) => isCustomerNewOneTimeConsultationPrivateChatType(type))
    : [];

// export const fetchData = async () => {
//
//     getNotifications()
//         .then(notifications => {
//
//             dispatch(slice.actions.setNotifications(notifications));
//
//         })
//         .catch(error => {
//
//             console.log('fetchData getNotifications', {error});
//
//         })
//         .then(() => {
//
//             dispatch(slice.actions.setIsFetching(true));
//         });
// };
//
// export const view = (notifications) => {
//
//     viewNotificationsHttp(notifications)
//         .then(notifications => {
//
//             dispatch(slice.actions.setNotifications(notifications));
//
//         })
//         .catch(error => {
//
//             console.log('fetchData getNotifications', {error});
//
//         })
//         .then(() => {
//
//             dispatch(slice.actions.setIsFetching(true));
//         });
// };
