import '../utils/api';

export const ROUTE = '/admin/addPrivateMessage';

/**
 * @param {Api} connection
 * @param {array} withMembers
 */
export default function addPrivateMessage(connection, {chatId, content, additionalData}) {

    connection.send({
        action: ROUTE,
        data: {
            chatId,
            content,
            additionalData,
            memberType: 'SUPER_TYPE',
        },
        chatId,
        content,
        additionalData,
        memberType: 'SUPER_TYPE',
    });
}

export const isAddPrivateMessageRoute = route => ROUTE === route;
