import StageList from './StageList';

function Drawer() {


    return (
        <StageList/>
    );
}

export default Drawer;
