import {useSelector} from '../../store';
import {fetchData, selectIsFetched, selectIsFetching, selectStages} from '../../store/slices/stage';
import useAuth from '../../hooks/useAuth';
import {useEffect} from 'react';

function StagesProvider({children}) {

    const stages = useSelector(selectStages);
    const isStagesFetching = useSelector(selectIsFetching);
    const isStagesFetched = useSelector(selectIsFetched);
    const {isInitialized, isLoggedIn} = useAuth();

    useEffect(() => {

        if (stages === null && !isStagesFetching && isInitialized && isLoggedIn)
            fetchData();

    }, [stages, isStagesFetching, isInitialized, isLoggedIn]);

    return children;
}
export default StagesProvider;
