// third-party
import {createSlice} from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import {dispatch} from '../index';

// ----------------------------------------------------------------------

const initialState = {
    avatar: '',
    nickname: '',
    city: '',
    phone: '',
    email: '',
    isFetched: false,
    isUpdating: false,
};

const slice = createSlice({
    name: 'admin',
    initialState,
    reducers: {

        setAvatar(state, action) {
            state.avatar = action.payload;
        },
        setNickname(state, action) {
            state.nickname = action.payload;
        },
        setCity(state, action) {
            state.city = action.payload;
        },
        setPhone(state, action) {
            state.phone = action.payload;
        },
        setEmail(state, action) {
            state.email = action.payload;
        },

        setIsFetched(state, action) {
            state.isFetched = action.payload;
        },

        setIsUpdating(state, action) {
            state.isUpdating = action.payload;
        },

        setData(state, {payload: {
            avatar = '',
            nickname = '',
            city = '',
            phone = '',
            email = '',
        }}) {
            state.isFetched = true;

            state.avatar = avatar;
            state.nickname = nickname;
            state.city = city;
            state.phone = phone;
            state.email = email;
        },
    }
});

export default slice.reducer;

// ----------------------------------------------------------------------

export async function fetchData() {

    // return async () => {

        dispatch(slice.actions.setIsUpdating(true));

        try {
            const response = await axios.get('/api/customer/data');

            if (response.data.data) {

                response.data.data.avatar = response.data.data.avatar ? `${response.data.data.avatar}?v=${Date.now()}` : '';

                dispatch(slice.actions.setData({...response.data.data,}));

            } else {

                dispatch(slice.actions.setIsFetched(true));
            }

        } catch (error) {
            console.log({error});
        }

        dispatch(slice.actions.setIsFetched(true));
        dispatch(slice.actions.setIsUpdating(false));
    // };
}

export async function updateAvatar(formData) {

    let response = null;

    dispatch(slice.actions.setIsUpdating(true));

    try {
        response = await axios.post(
            '/api/customer/data/update',
            formData,
            {
                'Content-Type': 'multipart/form-data'
            }
        );

        if (response.data.data) {

            response.data.data.avatar = response.data.data.avatar ? `${response.data.data.avatar}?v=${Date.now()}` : '';
            dispatch(slice.actions.setData({
                ...response.data.data,
            }));
        }

    } catch (error) {

        response = error;
        console.log({error});
    }

    dispatch(slice.actions.setIsFetched(true));
    dispatch(slice.actions.setIsUpdating(false));

    return response;
}

export async function updateData({
         avatar,
         nickname,
         city,
         phone,
         email,
    },
    headers = {}
) {
    dispatch(slice.actions.setIsUpdating(true));

    return new Promise((resolve, reject) => {

        axios.post('/api/customer/data/update', {
                avatar,
                nickname,
                city,
                phone,
                email,
            },
            headers
        )
            .then(response => {

                if (response.data.data && response.data.result === 0) {

                    const avatar = response.data.data.avatar ? `${response.data.data.avatar}?v=${Date.now()}` : '';

                    dispatch(slice.actions.setData({
                        ...{...response.data.data, avatar},
                    }));

                    dispatch(slice.actions.setIsFetched(true));
                    dispatch(slice.actions.setIsUpdating(false));

                    resolve(response);
                }

                dispatch(slice.actions.setIsFetched(true));
                dispatch(slice.actions.setIsUpdating(false));

                reject(response);
            })
            .catch(error => {

                dispatch(slice.actions.setIsFetched(true));
                dispatch(slice.actions.setIsUpdating(false));

                reject(error);
            })
        ;
    });
}

export const selectAvatar = ({lawyer: {avatar}}) => avatar;
export const selectNickname = ({lawyer: {nickname}}) => nickname;
export const selectCity = ({lawyer: {city}}) => city;
export const selectPhone = ({lawyer: {phone}}) => phone;
export const selectEmail = ({lawyer: {email}}) => email;
export const selectAll = ({lawyer: {
    avatar,
    nickname,
    city,
    phone,
    email,
}}) => ({
    avatar,
    nickname,
    city,
    phone,
    email,
});

export const selectIsEmpty = ({lawyer: {
    avatar,
    nickname,
    city,
    phone,
    email,
}}) => !avatar
    && !nickname
    && !city
    && !phone
    && !email
;

export const selectIsFetched = ({lawyer: {isFetched}}) => isFetched;
export const selectIsUpdating = ({lawyer: {isUpdating}}) => isUpdating;

