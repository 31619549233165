// third-party
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

import fetchLawyersApi from 'api/lawyers/fetchLawyers';
import activateLawyerApi from 'api/lawyers/activateLawyer';
import deactivateLawyerApi from 'api/lawyers/deactivateLawyer';

// ----------------------------------------------------------------------

export const fetchLawyers = createAsyncThunk('lawyers/fetch', async () => {

    const lawyers = await fetchLawyersApi();

    console.log({lawyers});

    return lawyers;
});

export const activateLawyer = createAsyncThunk('lawyers/lawyer/activate', async (lawyerId) => {

    const response = await activateLawyerApi(lawyerId);

    console.log({response});

    return response;
});

export const deactivateLawyer = createAsyncThunk('lawyers/lawyer/deactivate', async (lawyerId) => {

    const response = await deactivateLawyerApi(lawyerId);

    console.log({response});

    return response;
});

const initialState = {
    lawyers: null,
    updating: true,
};

const slice = createSlice({
    name: 'lawyers',
    initialState,
    reducers: {
        setLawyers(state, {payload}) {state.lawyers = payload;},
        setUpdating(state, {payload}) {state.updating = payload;},
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLawyers.pending, (state, action) => {
                state.updating = true;
            })
            .addCase(fetchLawyers.fulfilled, (state, {payload}) => {
                state.updating = false;
                console.log({payload});
                state.lawyers = payload.map(lawyer => ({...lawyer, updating: false}));
            })
            .addCase(fetchLawyers.rejected, (state, action) => {
                state.updating = true;
            })


            .addCase(activateLawyer.pending, (state, {meta: {arg: lawyerId}}) => {
                console.log('deactivateLawyer.pending', {lawyerId});
                state.lawyers = state.lawyers.map(lawyer => ({...lawyer, updating: lawyerId === lawyer.id}));
            })
            .addCase(activateLawyer.fulfilled, (state, {payload}) => {

                state.lawyers = state.lawyers.map(lawyer => (

                    lawyer.id === payload.lawyer.id
                        ? {...lawyer, ...payload.lawyer, updating: false}
                        : lawyer
                ));
            })
            .addCase(activateLawyer.rejected, (state, {payload}) => {

                state.lawyers = state.lawyers.map(lawyer => ({...lawyer, updating: payload.lawyerId === lawyer.id ? false : lawyer.updating}));
            })


            .addCase(deactivateLawyer.pending, (state, {meta: {arg: lawyerId}}) => {
                console.log('deactivateLawyer.pending', {lawyerId});
                state.lawyers = state.lawyers.map(lawyer => ({...lawyer, updating: lawyerId === lawyer.id}));
            })
            .addCase(deactivateLawyer.fulfilled, (state, {payload}) => {

                state.lawyers = state.lawyers.map(lawyer => (

                    lawyer.id === payload.lawyer.id
                        ? {...lawyer, ...payload.lawyer, updating: false}
                        : lawyer
                ));
            })
            .addCase(deactivateLawyer.rejected, (state, {payload}) => {

                state.lawyers = state.lawyers.map(lawyer => ({...lawyer, updating: payload.lawyerId === lawyer.id ? false : lawyer.updating}));
            })
    },
});

// Reducer
export default slice.reducer;


export const {
    setLawyers,
    setUpdating,
} = slice.actions;

// ----------------------------------------------------------------------

export const selectLawyers = ({lawyers: {lawyers}}) => lawyers;
export const selectUpdating = ({lawyers: {updating}}) => updating;

