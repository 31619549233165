import PrivateChat from '../../../../features/PrivateChat';

function Stages() {

    return (
        <PrivateChat/>
    );
}

export default Stages;
