import {Grid, useTheme} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

function Loading() {

    return (
        <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item>
                <CircularProgress
                    size={20}
                    sx={{color: (theme) => theme.palette.grey[500]}}
                />
            </Grid>
        </Grid>
    );
}

export default Loading;
