import CurrencyFormat from 'react-currency-format';

function Currency({value}) {

    return (
        <CurrencyFormat
            value={value}
            thousandSeparator={' '}
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            displayType="text"
            renderText={
                value => (
                    <span style={{whiteSpace: 'pre'}}>{value.replace(',00', '')}</span>
                )
            }
        />
    );
}

export default Currency;
