import {useEffect} from 'react';
import {
    fetchData,
    selectChats,
    selectCurrentChat,
    selectFilter,
    selectIsFetched, setChats, setCurrentChat
} from '../../store/slices/anonymousChat';
import {useDispatch, useSelector} from '../../store';
import {useParams} from 'react-router-dom';
import getChat from '../../api/anonymousChat/getChat';

function AnonymousChatsProvider({children}) {

    const dispatch = useDispatch();

    const {anonymousChatId} = useParams();

    const chats = useSelector(selectChats);

    const currentChat = useSelector(selectCurrentChat);

    const isFetched = useSelector(selectIsFetched);

    const filter = useSelector(selectFilter);

    useEffect(() => {

        fetchData(filter);

    }, []);

    useEffect(() => {

        if (anonymousChatId && isFetched && chats && (!currentChat || currentChat.id !== anonymousChatId)) {

            const chatFromChats = chats.find(({id}) => id === anonymousChatId);

            if (chatFromChats) {

                dispatch(setCurrentChat(chatFromChats));

            } else {

                getChat(anonymousChatId)
                    .then(({chat: fetchedChat}) => {

                        dispatch(setCurrentChat(fetchedChat));
                    });
            }
        }

    }, [anonymousChatId, chats, isFetched]);

    return children;
}

export default AnonymousChatsProvider;
