// import React from 'react';
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import {JWTProvider as AuthProvider} from 'contexts/JWTByPhoneContext';
import RedirectAfterLoginProvider from './utils/providers/RedirectAfterLoginProvider';
import LocalizationProvider from '@mui/lab/LocalizationProvider/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {ru} from 'date-fns/locale';
import {CookiesProvider} from 'react-cookie';
import UserTypeGuard from './utils/route-guard/UserTypeGuard';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <RTLLayout>
            <CookiesProvider>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <UserTypeGuard>
                                <RedirectAfterLoginProvider>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                        locale={ru}
                                    >
                                                    <>
                                                        <Routes/>
                                                        <Snackbar/>
                                                    </>
                                    </LocalizationProvider>
                                </RedirectAfterLoginProvider>
                            </UserTypeGuard>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </CookiesProvider>
        </RTLLayout>
    </ThemeCustomization>
);

export default App;
