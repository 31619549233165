import {Typography, useTheme} from '@mui/material';
import Box from '@mui/material/Box';

function Header({uid}) {

    const theme = useTheme();

    return (
        <Typography
            title={`Дело ${uid}`}
            variant="h5"
            color={theme.palette.text.secondary}
            sx={{position: 'relative', display: 'block', zIndex: 20, textDecoration: 'none', px: 2,}}
        >
            Дело №{uid}
        </Typography>
    );
}

export default Header
