import {useEffect, useState} from 'react';
import {
    Badge,
    Box,
    Chip,
    Grid,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Typography,
    useTheme
} from '@mui/material';
import {
    isStageCompletedStatus,
    isStageWaitStatus, STAGE_CONTROVERSIAL_SITUATION_STATUS, STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS
} from '../../../store/slices/lawCase';
import StageAvatar from './StageAvatar';
import previewText from '../../../utils/previewText';
import Currency from '../../../ui-component/Currency';
import getNumeralDeclinationWord from '../../../utils/getNumeralDeclinationWord';
import getTextPreview from '../../../utils/getTextPreview';
import getFormatRuDate from '../../../utils/getFormatRuDate';
import styled from '@mui/material/styles/styled';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import Alert from '@mui/material/Alert/Alert';
import {useNavigate} from 'react-router-dom';
import urls from '../../../utils/urls';

function LabelValueRow({label, value}) {

    return (
        <Box pt={.5}>
            <Typography component="div" variant="caption">{label}</Typography>
            <Typography component="div" variant="subtitle1">{value}</Typography>
        </Box>
    );
}

const StyledBadge = styled(Badge)({
    '& .MuiBadge-badge': {
        left: 10,
        top: 10
    },
    '&': {
        display: 'flex'
    }
});

function StageListItem({
    filteredChats,
    stage,
    selected,
    setCurrentChat,
    isFirstStage,
}) {
    const theme = useTheme();
    const navigate = useNavigate();
    const chat = filteredChats.find(({id}) => id === stage.chatId);
    const {id: stageId, cost, balance, newCost, status, history} = stage;
    const publicChatFirstMessage = chat && chat.hasOwnProperty('additional') && chat.additional && chat.additional.hasOwnProperty('publicChatFirstMessage')
        ? chat.additional.publicChatFirstMessage
        : '';
    const lastMessage = chat ? getTextPreview(chat.lastMessage) : '';
    const lastDate = !chat
        ? ''
        : getFormatRuDate({
            date: new Date(
                chat.messages.length === 0
                    ? chat.date
                    : Math.max(...chat.messages.map(({date}) => date))
            ),
            withTime: true,
            hideCurrentYear: true,
            timeIsFirst: true,
            hideCurrentDayAndMonth: true
        });
    const isOneTimeConsultationChat = chat && chat.hasOwnProperty('type') && chat.type === 'ONE_TIME_CONSULTATION_PRIVATE_TYPE';
    const [requestedNewCost, setRequestedNewCost] = useState(newCost);

    const onClick = e => {

        e.preventDefault();

        // if (chat) {
        //
        //     setCurrentChat(chat);
        //
        // } else {
        //
        //     navigate(`${urls.caseStage}/${stageId}`);
        // }

        setCurrentChat(chat || null);

        if (!chat)
            navigate(`${urls.caseStage}/${stageId}`);
    };

    useEffect(() => {

        setRequestedNewCost(newCost);

    }, [newCost]);

    return (
        <Box>
            <StyledBadge
                color="error"
                variant="dot"
                invisible
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
            >
                <ListItemButton
                    onClick={onClick}
                    selected={selected}
                    // disabled={!(history.includes(STAGE_CONTROVERSIAL_SITUATION_STATUS) || history.includes(STAGE_CONTROVERSIAL_SITUATION_WITH_JURSCANNER_STATUS))}
                    sx={{maxWidth: '100%'}}
                >
                    <ListItemAvatar sx={{minWidth: 'auto', marginRight: 1, alignSelf: 'flex-start'}}>
                        <StageAvatar status={status}/>
                    </ListItemAvatar>
                    <ListItemText
                        primary={
                            <Grid container alignItems="center" spacing={1} component="span">
                                <Grid item xs zeroMinWidth component="span">
                                    <Typography
                                        variant="h5"
                                        color="inherit"
                                        component="span"
                                        sx={{display: 'block'}}
                                        dangerouslySetInnerHTML={{
                                            __html: stage.name.replaceAll(' / ', '/').replaceAll('/', ' / ')
                                        }}
                                    />
                                </Grid>
                                {
                                    lastDate &&
                                    <Grid item component="div" sx={{alignSelf: 'flex-start'}}>
                                        <Typography component="div" variant="subtitle2">
                                            {lastDate}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    publicChatFirstMessage &&
                                    <Grid item xs={12} component="div">
                                        <Typography component="div" variant="subtitle2" title={publicChatFirstMessage}>
                                            {
                                                isOneTimeConsultationChat
                                                    ? previewText(publicChatFirstMessage)
                                                    : <>Из анонимного чата: {previewText(publicChatFirstMessage)}</>
                                            }
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    lastMessage && !publicChatFirstMessage &&
                                    <Grid item xs={12} component="div">
                                        <Typography component="div" variant="subtitle2" dangerouslySetInnerHTML={{__html: lastMessage}}/>
                                    </Grid>
                                }
                                {
                                    requestedNewCost &&
                                    <Grid item xs={12} component="div">
                                        <Alert
                                            variant="filled"
                                            color="info"
                                            severity="error"
                                            icon={<HourglassTopRoundedIcon fontSize="small"/>}
                                        >
                                            <Typography
                                                component="div"
                                                variant="caption"
                                                color={theme.palette.invertText.main}
                                            >
                                                Ожидается изменение стоимости: <br/>
                                                <b>
                                                    <Currency value={requestedNewCost}/>&nbsp;{getNumeralDeclinationWord(requestedNewCost, ['рубль', 'рубля', 'рублей'])}
                                                </b>
                                            </Typography>
                                        </Alert>
                                    </Grid>
                                }
                                {
                                    cost !== null && cost > 0 &&
                                    <Grid item xs={12} component="div">
                                        <LabelValueRow
                                            label={isFirstStage ? 'Стоимость консультации' : 'Стоимость этапа'}
                                            value={
                                                <><Currency
                                                    value={cost}/>&nbsp;{getNumeralDeclinationWord(cost, ['рубль', 'рубля', 'рублей'])}{balance === cost && <> (оплачено)</>}</>
                                            }
                                        />

                                        {
                                            !isStageWaitStatus(status) && !isStageCompletedStatus(status) &&
                                            <LabelValueRow
                                                label={<>Баланс &quot;Безопасной сделки&quot;</>}
                                                value={
                                                    <><Currency
                                                        value={balance}/>&nbsp;{getNumeralDeclinationWord(balance, ['рубль', 'рубля', 'рублей'])}</>
                                                }
                                            />
                                        }
                                    </Grid>
                                }
                            </Grid>
                        }
                        secondary={
                            chat && chat.status
                                ? <Grid container alignItems="center" spacing={1} component="span">
                                    <Grid item xs zeroMinWidth component="span">
                                        <Typography
                                            variant="caption"
                                            component="span"
                                            sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                display: 'block'
                                            }}
                                        >
                                            {chat.status}
                                        </Typography>
                                    </Grid>
                                    <Grid item component="span">
                                        {chat.unReadChatCount !== 0 && (
                                            <Chip
                                                label={chat.unReadChatCount}
                                                component="span"
                                                color="secondary"
                                                sx={{
                                                    width: 20,
                                                    height: 20,
                                                    '& .MuiChip-label': {
                                                        px: 0.5
                                                    }
                                                }}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                                : null
                        }
                    />
                </ListItemButton>
            </StyledBadge>
        </Box>
    );
}

export default StageListItem;
