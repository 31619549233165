import {Grid, IconButton, Typography, useMediaQuery, useTheme} from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Box from '@mui/material/Box';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';
import {CHAT_STAGE_VIEW, SETTING_STAGE_VIEW} from './index';
import {useParams} from 'react-router-dom';



function Header({showDrawerOpen, handleDrawerOpen, avatar, avatars, nickname, icon, headerButton, children, stageView, setStageView}) {

    const theme = useTheme();

    const matchDownSM = useMediaQuery(theme.breakpoints.down('lg'));

    const {stageId: stageIdParam} = useParams();

    return (
        <>
            <Grid container alignItems="center" rowSpacing={1} columnSpacing={0}>
                {
                    (matchDownSM && showDrawerOpen) &&
                    <Grid item>
                        <IconButton
                            onClick={handleDrawerOpen}
                            edge="start"
                        >
                            <MenuRoundedIcon fontSize="inherit"/>
                        </IconButton>
                    </Grid>
                }

                <Grid item sx={{maxWidth: {xs: '45%', lg: '52%'}}}>
                    <Grid container spacing={2} alignItems="center" sx={{flexWrap: 'nowrap'}}>
                        <Grid item sm zeroMinWidth>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography variant="h4" component="div">
                                        {nickname}{' '}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/*{*/}
                {/*    headerButton &&*/}
                {/*    <Grid item sx={{ml: "auto"}}>*/}
                {/*        <HeaderButton*/}
                {/*            hintText={headerButton.hintText}*/}
                {/*            buttonText={headerButton.buttonText}*/}
                {/*            loading={headerButton.loading}*/}
                {/*            disabled={headerButton.disabled}*/}
                {/*            onClick={headerButton.onClick}*/}
                {/*        />*/}
                {/*        yo*/}
                {/*    </Grid>*/}
                {/*}*/}
                <Grid item sx={{ml: "auto"}}>
                    <Grid container>
                        <Grid>
                            <IconButton
                                size="small"
                                color={CHAT_STAGE_VIEW === stageView ? "primary" : "default"}
                                onClick={() => setStageView(CHAT_STAGE_VIEW)}
                                disabled={!!stageIdParam}
                            >
                                <ForumRoundedIcon />
                            </IconButton>
                            <IconButton
                                size="small"
                                color={SETTING_STAGE_VIEW === stageView ? "primary" : "default"}
                                onClick={() => setStageView(SETTING_STAGE_VIEW)}
                            >
                                <EditRoundedIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                {
                    children &&
                    <Grid item sx={{ml: "auto"}}>
                        {children}
                    </Grid>
                }
            </Grid>
            <Box pb={2}/>
        </>
    );
}

export default Header;
