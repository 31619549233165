import Button from '@mui/material/Button';
import {Link} from 'react-router-dom';
import urls from '../../../utils/urls';

function Footer({lawCase, selected}) {

    const isAdminChat = lawCase && !!lawCase.adminChatId;

    if (!isAdminChat)
        return null;

    return (
        <Button
            component={Link}
            to={`${urls.caseStageChat}/${lawCase.adminChatId}`}
            sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'center',
                borderRadius: 0,
            }}
            variant={selected ? 'contained' : 'text'}
        >
            Чат с Юрасканнер
        </Button>
    );
}

export default Footer;
