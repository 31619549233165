// project imports
import MinimalLayout from 'layout/MinimalLayout';
import urls from '../utils/urls';
import Loadable from '../ui-component/Loadable';
import {lazy} from 'react';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const Logout = Loadable(lazy(() => import('views/pages/authentication/Logout')));

const AuthenticationRoutes = {
    path: urls.root,
    element: <MinimalLayout />,
    children: [{
        path: urls.signOut,
        element: <Logout />
    }],
};

export default AuthenticationRoutes;
