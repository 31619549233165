import PropTypes from 'prop-types';

// project imports
import urls from '../urls';
import useAuth from '../../hooks/useAuth';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

// ==============================|| GUEST GUARD ||============================== //

const ROLE_USER = 'ROLE_USER';
const ROLE_ADMIN = 'ROLE_ADMIN';

const isRoute = ({routes, pathname}) =>
    routes.findIndex(route => pathname.includes(route)) > -1;

const UserTypeGuard = ({children}) => {

    const {user, isLoggedIn} = useAuth();
    const navigate = useNavigate();

    useEffect(() => {

        if (isLoggedIn && user.roles && user.roles.indexOf(ROLE_ADMIN) === -1)
            navigate(urls.underConstruction);

    }, [navigate, user, isLoggedIn]);

    return children;
};

UserTypeGuard.propTypes = {
    children: PropTypes.node
};

export default UserTypeGuard;
