import PropTypes from 'prop-types';
import {useTheme} from '@mui/material/styles';
import {Box, Drawer as MuiDrawer, useMediaQuery} from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from '../../../ui-component/cards/MainCard';
import {appDrawerWidth} from '../../../store/constant';
import useConfig from '../../../hooks/useConfig';
import Divider from '@mui/material/Divider';

const Drawer = ({handleDrawerOpen, openDrawer, header = null, footer = null, children}) => {

    const theme = useTheme();
    const {borderRadius} = useConfig();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const drawerBg = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50';

    return (
        <MuiDrawer
            sx={{
                width: appDrawerWidth,
                maxWidth: '90%',
                flexShrink: 0,
                zIndex: { xs: 1100, lg: 0 },
                height: matchDownLG ? '100vh' : 'calc(100vh - 181px)',
                '& .MuiDrawer-paper': {
                    boxSizing: 'border-box',
                    position: 'relative',
                    border: 'none',
                    borderRadius: matchDownLG ? '0' : `${borderRadius}px`,
                    height: '100%',
                }
            }}
            variant={matchDownLG ? 'temporary' : 'persistent'}
            anchor="left"
            open={openDrawer}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            {
                openDrawer &&
                <MainCard
                    sx={{
                        backgroundColor: matchDownLG ? 'transparent' : drawerBg,
                        borderRadius: matchDownLG ? '0' : `${borderRadius}px`,
                        height: '100%',
                    }}
                    border={!matchDownLG}
                    content={false}
                >
                    <Box sx={{
                        height: '100%',
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        justifyContent: 'flex-start',
                        alignItems: 'stretch',
                    }}>
                        {
                            header &&
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    flexShrink: 0,
                                }}
                            >
                                <Box pt={2} pb={2}>
                                    {header}
                                </Box>
                                <Divider/>
                            </Box>
                        }
                        <Box
                            sx={{
                                flexGrow: 1,
                                flexShrink: 1,
                                flexBasis: 'auto',
                                height: 0,
                            }}
                        >
                            <PerfectScrollbar
                                style={{
                                    overflowX: 'hidden',
                                    height: '100%',
                                }}
                            >
                                <Box sx={{ p: 0, pt: 0 }}>
                                    {children}
                                </Box>
                            </PerfectScrollbar>
                        </Box>
                        {
                            footer &&
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    flexShrink: 0,
                                }}
                            >
                                <Divider/>
                                {footer}
                            </Box>
                        }
                    </Box>
                </MainCard>
            }
        </MuiDrawer>
    );
};

Drawer.propTypes = {
    handleDrawerOpen: PropTypes.func,
    openDrawer: PropTypes.bool,
};

export default Drawer;
