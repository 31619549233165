import api from './axios';

export default function fileGetRequest(url, params = {}, config = {}) {

    return new Promise((resolve, reject) => {

        api.get(
            url,
            {
                ...config,
                params,
                responseType: 'arraybuffer',
        })
            .then(response => {

                resolve({
                    file: response.data,
                    contentType: response.headers['content-type'],
                });
            })
            .catch(error => {

                reject(error);
            })
            .then(() => {

                reject();
            });
    });
}
