import fileGetRequest from '../../../utils/fileGetRequest';

export default async function getFile(url)
{
    const response = await fileGetRequest(url);

    console.log({response});

    return response;
}
