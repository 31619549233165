import PropTypes from 'prop-types';
import {Grid, Typography} from '@mui/material';
import {gridSpacing} from '../store/constant';

function EmptyText({text = '', sx = {}, content = null}) {

    return (
        <Grid
            container
            spacing={gridSpacing}
            sx={{
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid item>
                {
                    content
                    ||
                    <Typography
                        variant="h2"
                        sx={{opacity: .4, ...sx}}
                    >
                        {text}
                    </Typography>
                }
            </Grid>
        </Grid>
    );
}

EmptyText.propTypes = {
    text: PropTypes.string
};

export default EmptyText
