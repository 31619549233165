import PropTypes from 'prop-types';
import {createContext, useEffect, useReducer} from 'react';
// third-party
import {Chance} from 'chance';
import jwtDecode from 'jwt-decode';
// reducer - state management
import {LOGIN, LOGOUT, REDIRECT_AFTER_LOGIN} from 'store/actions';
import accountReducer from 'store/accountReducer';
// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import useRedirectAfterLoginUrl from '../hooks/useRedirectAfterLoginUrl';
import emailRequestApi from '../api/auth/emailRquest';
import emailAndOTPConfirmApi from '../api/auth/emailAndOTPConfirm';

const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    redirectAfterLoginUrl: null,
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {

    if (serviceToken) {

        const headers = {
            'Authorization': `Bearer ${serviceToken}`,
            'X-Auth': `Bearer ${serviceToken}`,
        };
        localStorage.setItem('token', serviceToken);
        axios.defaults.headers.common = headers;
    } else {
        localStorage.removeItem('token');
        delete axios.defaults.headers.common.Authorization;
        delete axios.defaults.headers.common['X-Auth'];
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({children}) => {

    const [state, dispatch] = useReducer(accountReducer, initialState);

    const {current: redirectAfterLoginUrl} = useRedirectAfterLoginUrl();

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('token');

                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);

                    const response = await axios.get('/api/user');
                    console.log({response});
                    const payload = {isLoggedIn: true,};

                    if (response.status === 200) {
                        payload.user = response.data;
                    }

                    if (redirectAfterLoginUrl !== '') {
                        payload.redirectAfterLoginUrl = redirectAfterLoginUrl;
                    }

                    dispatch({
                        type: LOGIN,
                        payload
                    });
                } else {
                    dispatch({
                        type: LOGOUT,
                        user: null
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT,
                    user: null
                });
            }
        };

        init();
    }, []);

    const phoneAndEmailRequest = async ({phone, email}) => {

        const response = await axios.post(`${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SIGN_IN_PHOEN_REQUEST}`, {phone, email});

        if (response.hasOwnProperty('data') && response.data && response.data.hasOwnProperty('result') && response.data.result === 0) {

            return response.data;
        }
        return null;
    };

    const phoneAndEmailConfirm = async ({phone, phoneCode, phoneToken, email, emailCode, emailToken}) => {

        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_SIGN_IN_PHOEN_CONFIRM}`,
            {phone, phoneCode, phoneToken, email, emailCode, emailToken}
        );

        if (response.hasOwnProperty('data') && response.data && response.data.hasOwnProperty('result') && response.data.result === 0) {

            setSession(response.data.token);

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: response.data.user,
                    redirectAfterLoginUrl: redirectAfterLoginUrl !== '' ? redirectAfterLoginUrl : redirectAfterLoginUrl,
                }
            });

            return response.data;
        }
        return null;
    };

    // eslint-disable-next-line no-return-await
    const emailRequest = async (email) => await emailRequestApi(email);

    // eslint-disable-next-line no-return-await
    const emailAndOTPConfirm = async ({email, emailCode, emailToken, authCode}) => {

        const response = await emailAndOTPConfirmApi({email, emailCode, emailToken, authCode});
        console.log({response});
        if (response.hasOwnProperty('result') && response.result === 0) {

            setSession(response.token);

            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user: response.user,
                    redirectAfterLoginUrl: redirectAfterLoginUrl !== '' ? redirectAfterLoginUrl : redirectAfterLoginUrl,
                }
            });

            return response
        }

        if (response.hasOwnProperty('message')) {

            throw new Error(response.message);
        }

        throw new Error('Возникла ошибка.');
    };

    const logout = () => {

        setSession(null);

        dispatch({type: LOGOUT});
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    const clearRedirectAfterLoginUrl = () => {

        dispatch({
            type: REDIRECT_AFTER_LOGIN
        });

    };

    return <JWTContext.Provider value={{...state, redirectAfterLoginUrl, clearRedirectAfterLoginUrl, phoneAndEmailRequest, phoneAndEmailConfirm, logout, emailRequest, emailAndOTPConfirm,}}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
