import axios from 'utils/axios';

export default async function activateLawyer(lawyerId) {

    try {

        const response = await axios.post('/api/admin/lawyer/activate', {lawyerId});

        console.log(response);

        return response.data;

    } catch (e) {

        console.log({e});

        return {lawyerId}
    }
}
