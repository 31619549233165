import axios from 'utils/axios';

export default async function fetchLawyers() {

    const response = await axios.get('/api/admin/lawyers');

    console.log(response);

    return response.data.lawyers;
}
