import {
    IconPhoto,
    // IconFileSpreadsheet,
    IconFileText,
    IconFileZip,
    IconFile,
    // IconFileChart,
    // IconFileVector,
} from '@tabler/icons';

const IMAGE_EXTENSIONS = [
    'apng',
    'avif',
    'gif',
    'jpg',
    'jpe',
    'jpeg',
    'jfi',
    'jfif',
    'jif',
    'pjpeg',
    'pjp',
    'png',
    'webp',
    'bmp',
    'ico',
    'cur',
    'tif',
    'tiff',
    'heif',
    'heic',
];

const VECTOR_EXTENSIONS = [
    'svg',
    'svgz',
    'ai',
    'eps',
];

const TEXT_EXTENSIONS = [
    'doc',
    'docx',
    'odt',
    'pdf',
    'txt',
    'rtf',
];

const SPREADSHEET_EXTENSIONS = [
    'xls',
    'xlsx',
    'ods',
    'csv',
];

const PRESENTATION_EXTENSIONS = [
    'odp',
    'pps',
    'ppt',
    'pptx',
];

const ARCHIVE_EXTENSIONS = [
    '7z',
    'arj',
    'deb',
    'pkg',
    'rar',
    'rpm',
    'gz',
    'z',
    'zip',
];

const EXTENSION_MAP = [
    {
        extensionList: IMAGE_EXTENSIONS,
        icon: IconPhoto,
    },
    {
        extensionList: VECTOR_EXTENSIONS,
        icon: IconFile,
    },
    {
        extensionList: TEXT_EXTENSIONS,
        icon: IconFileText,
    },
    {
        extensionList: SPREADSHEET_EXTENSIONS,
        icon: IconFileText,
    },
    {
        extensionList: PRESENTATION_EXTENSIONS,
        icon: IconFileText,
    },
    {
        extensionList: ARCHIVE_EXTENSIONS,
        icon: IconFileZip,
    },
];

function FileIcon({fileName, ...others}) {

    const extension = fileName.split('.').pop();

    const Icon = getIcon(extension);

    return (
        <Icon {...others} />
    );
}

export default FileIcon;

function getIcon(extension) {

    for (let i = 0; i < EXTENSION_MAP.length; i++) {

        const item = EXTENSION_MAP[i];

        if (item.extensionList.includes(extension.toLowerCase()))
            return item.icon;
    }

    return IconFile;
}
